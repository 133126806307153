import { DONATION_EVENT_ID, TEN_MINUTES_IN_MS } from '@utils/constants';
import { LocalNotificationSchema } from '@capacitor/local-notifications/dist/esm/definitions';
import {
    NotificationService,
    NotificationType,
} from '../../services/notification';
import { ReactI18NextChild, Trans, useTranslation } from 'react-i18next';
import { StorageServices } from '../../services/storage';
import { directusClient } from '@api/directus-client';
import React, { useEffect, useState } from 'react';

const handleProlongedWatchingTime = async () => {
    const currentCount = await StorageServices.getLivestreamViewCount();
    const donationReminders = await directusClient.getAllDonationReminders();
    donationReminders?.forEach((reminder) => {
        if (currentCount + 1 === reminder.num_livestreams) {
            //TODO: check for current total donation compared to reminder.max_donated_cents
            const notification: LocalNotificationSchema = {
                title: reminder.title ?? '',
                body: reminder.description ?? '',
                id: DONATION_EVENT_ID + reminder.id,
                extra: {
                    route: reminder.donation_link
                        ? `/foerdern/${reminder.donation_link}?amount=${reminder.suggested_donation_amount_cents}`
                        : undefined,
                    type: NotificationType.DONATION_REMINDER,
                },
            };
            void NotificationService.scheduleNotifications([notification]);
        }
    });
    await StorageServices.setLivestreamViewCount(currentCount + 1);
};

function LivestreamConsent({
    setConsent,
}: {
    setConsent: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [remember, setRemember] = React.useState(false);
    const { t } = useTranslation('event');
    return (
        <div
            className="h-60 bg-greyDark text-white"
            data-testid="livestream-consent"
            data-cy="livestream-consent"
        >
            <p className="pr-4 pl-[68px] pt-16 text-sm">
                <Trans i18nKey="consentTitle" t={t}>
                    Dieser Inhalt wird von{' '}
                    <a
                        href="https://www.youtube.com"
                        className="text-himmelblau"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {
                            {
                                provider: 'Youtube.com',
                            } as unknown as ReactI18NextChild
                        }
                    </a>{' '}
                    gehostet
                </Trans>
            </p>
            <p className="px-4 pt-4 text-sm">
                <Trans i18nKey="consentDescription" t={t}>
                    Durch die Anzeige des externen Inhalts akzeptieren sie die{' '}
                    <a
                        href="https://www.youtube.com/static?gl=DE&template=terms&hl=de"
                        className="text-himmelblau"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {
                            {
                                termsOfService: 'Nutzungsbedingungen',
                            } as unknown as ReactI18NextChild
                        }
                    </a>{' '}
                    von{' '}
                    <a
                        href="https://www.youtube.com"
                        className="text-himmelblau"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {
                            {
                                provider: 'Youtube.com',
                            } as unknown as ReactI18NextChild
                        }
                    </a>
                    .
                </Trans>
            </p>
            <div className="px-4 flex items-end">
                <div className="grow pr-4">
                    <label className="pt-4 text-xs h-max flex flex-row items-center">
                        <input
                            className="mr-3 h-[18px] w-[18px] appearance-none rounded border border-2 border-checkboxBorder bg-greyDark checked:bg-himmelblau checked:border-himmelblau focus:outline-none"
                            type="checkbox"
                            checked={remember}
                            onChange={(v) => setRemember(v.target.checked)}
                            data-cy="rememberConsent"
                            data-testid="rememberConsent"
                        />
                        {t('consentRemember')}
                    </label>
                    <p className="pt-2 text-xs">{t('consentNote')}</p>
                </div>
                <button
                    className="p-2 bg-rosenholz rounded-lg w-56 text-center"
                    onClick={() => {
                        if (remember) {
                            void StorageServices.setLivestreamConsent(true);
                        }
                        setConsent(true);
                    }}
                    data-cy="consentButton"
                    data-testid="consentButton"
                >
                    {t('consentAccept')}
                </button>
            </div>
        </div>
    );
}

export function Livestream() {
    const [consent, setConsent] = useState<boolean>(false);

    useEffect(() => {
        void StorageServices.getLivestreamConsent().then((res) => {
            setConsent(res);
        });
    }, []);

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined;
        if (consent) {
            timeout = setTimeout(() => {
                void handleProlongedWatchingTime();
            }, TEN_MINUTES_IN_MS);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [consent]);

    return consent ? (
        <iframe
            title="livestream"
            width="100%"
            height="240"
            src="https://www.youtube.com/embed/live_stream?channel=UCEQe6raiXw8RC27ORl5bArA"
            allowFullScreen
            data-testid="livestream-iframe"
            data-cy="livestream-iframe"
        />
    ) : (
        <LivestreamConsent setConsent={setConsent} />
    );
}
