import { ViewsType } from '@typesSrc/views';
import { useSearchParams } from 'react-router-dom';
import BaseView from '@views/base-view';
import FullscreenModalView from '@views/fullscreen-modal-view';
import React from 'react';
import Success from '@components/success';

export default function DonationSuccess() {
    const [searchParams] = useSearchParams();
    let donationTitle = searchParams.get('donationTitle');
    if (!donationTitle) {
        donationTitle = ' ';
    }
    return (
        <FullscreenModalView>
            <BaseView view={ViewsType.DONATION} title="Spendenseite">
                <Success
                    translations={'donation'}
                    donationTitle={donationTitle}
                    link={'/foerdern'}
                />
            </BaseView>
        </FullscreenModalView>
    );
}
