import { Outlet } from 'react-router-dom';
import { ViewsType } from '@typesSrc/views';
import BaseView from '@views/base-view';
import NewsOverview from '@components/news/news-overview';
import React from 'react';

function NewsOverviewView() {
    return (
        <BaseView
            title="Neuigkeiten"
            view={ViewsType.NEWS}
            enableScrollToTopBehavior
        >
            <NewsOverview />
            <Outlet />
        </BaseView>
    );
}

export default NewsOverviewView;
