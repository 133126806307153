import { CheckoutState, useCheckout } from '../../hooks/useCheckout';
import { ReactComponent as ChevronLeft } from '@public/icons/chevron_left.svg';
import { Link, useParams } from 'react-router-dom';
import { LoadingButton } from '@components/loading-button';
import { LoadingSpinner } from '@components/loading-spinner';
import { NoData } from '@components/no-data-error';
import { Orientation, useOrientation } from '../../hooks/useOrientation';
import { ViewsType } from '@typesSrc/views';
import { matomo } from '../../html-head';
import { useDirectus } from '../../hooks/useDirectus';
import { useTranslation } from 'react-i18next';
import BaseView from '@views/base-view';
import FullscreenModalView from '@views/fullscreen-modal-view';
import Image from '@components/image';
import React, { ChangeEvent, useContext, useEffect } from 'react';
import SafeSpaceContext from '../../context/safe-space-context';
import Toggle from 'react-toggle';

function DonationAppealDetail() {
    const safeSpace = useContext(SafeSpaceContext);
    const { orientation } = useOrientation();
    const { t } = useTranslation('donation');
    const { triggerCheckout, state } = useCheckout();
    const { donationId } = useParams();
    const { data, loading } = useDirectus((directusClient) => {
        if (donationId) {
            return directusClient.getDonationAppeal(donationId);
        }
        return Promise.resolve(undefined);
    });

    const [needsReceipt, setNeedsReceipt] = React.useState(false);

    useEffect(() => {
        if (data) {
            matomo([
                'trackEvent',
                'Fördern',
                'Anzeige',
                `Spendenzweck: ${data.title}`,
            ]);
        }
    }, [data]);

    const onDonate = () => {
        const link = needsReceipt ? data?.linkWithBill : data?.linkWithoutBill;
        if (link) {
            if (data) {
                matomo([
                    'trackEvent',
                    'Fördern',
                    'Interaktion',
                    `Spendenzweck: ${data.title}`,
                ]);
            }
            triggerCheckout({
                priceId: link,
                successRoute: `/foerdern/erfolg?donationTitle=${data?.title}`,
                cancelRoute: `/foerdern/${donationId}`,
                type: 'donation',
                donationTitle: data?.title,
            });
        }
    };

    if (loading) {
        return (
            <FullscreenModalView>
                <BaseView
                    view={ViewsType.DONATION}
                    title="Spendenseite"
                    showHeader={true}
                >
                    <div className="w-screen h-[55vh] flex justify-center items-center">
                        <LoadingSpinner />
                    </div>
                </BaseView>
            </FullscreenModalView>
        );
    }

    if (!data) {
        return (
            <FullscreenModalView>
                <BaseView
                    view={ViewsType.DONATION}
                    title="Spendenseite"
                    showHeader={true}
                >
                    <NoData />
                </BaseView>
            </FullscreenModalView>
        );
    }

    return (
        <FullscreenModalView>
            <BaseView
                view={ViewsType.DONATION}
                title="Spendenseite"
                showHeader={false}
            >
                <div
                    className="relative"
                    style={{
                        paddingTop: `${safeSpace?.insets.top ?? 0}px`,
                    }}
                >
                    <Link
                        to="/foerdern"
                        className="absolute bg-white left-4 p-4 rounded-full h-10 w-10 flex justify-center items-center"
                        data-cy="donationsBackButton"
                        data-testid="donationsBackButton"
                        style={{
                            top:
                                data.image &&
                                orientation === Orientation.Portrait
                                    ? `${Math.max(
                                          16 + (safeSpace?.insets.top ?? 0),
                                          56,
                                      )}px`
                                    : `${16 + (safeSpace?.insets.top ?? 0)}px`,
                        }}
                    >
                        <ChevronLeft />
                    </Link>
                    {data.image && (
                        <Image
                            asset={data.image}
                            className="h-auto w-full object-cover max-h-60 sm:max-h-[40vh] phoneLandscape:h-20"
                            data-cy="newsThumbnail"
                            aspectRatio={16 / 9}
                        />
                    )}
                </div>
                <div className="flex justify-center mx-4 mt-4">
                    <div className="grow max-w-2xl pb-32">
                        <p className="font-medium text-3xl">{data.title}</p>

                        <div className="mt-6 whitespace-pre-line">
                            {data.description}
                        </div>

                        <button className="mt-6 flex flex-row justify-between items-center space-x-4 w-full">
                            <div className="text-sm text-greyDark text-left flex-1">
                                {t('detail.needsReceiptLabel')}
                            </div>

                            <Toggle
                                className="mx-4"
                                checked={needsReceipt}
                                icons={false}
                                onChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setNeedsReceipt(event.target.checked);
                                }}
                                data-testid="needsBill"
                                data-cy="needsBill"
                            />
                        </button>

                        <div className="text-sm text-greyDark text-left mt-2">
                            {t('detail.receiptInfo')}
                        </div>

                        <div className="w-screen absolute bottom-24 left-0 flex flex-col items-center">
                            <div className="relative top-0.5 bg-gradient-to-t from-white w-full min-h-[40px] phoneLandscape:min-h-[0px]" />
                            <div className="w-full py-4 px-4 border-b-lightGray border-b bg-white flex justify-center">
                                <LoadingButton
                                    className="w-full max-w-2xl p-2 bg-rosenholz rounded-lg text-center text-white"
                                    onClick={onDonate}
                                    isLoading={state === CheckoutState.PENDING}
                                    data-cy="donateButton"
                                    dataTestId="donateButton"
                                    disabled={
                                        needsReceipt
                                            ? !data.linkWithBill
                                            : !data.linkWithoutBill
                                    }
                                >
                                    {t('startscreen.donateNow')}
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </div>
            </BaseView>
        </FullscreenModalView>
    );
}

export default DonationAppealDetail;
