import { generateSourceSet } from '@utils/loader';
import { useMemo } from 'react';

export default (html?: string): string =>
    useMemo(
        () =>
            html?.replaceAll(
                /(<img.+?)src="(.+?)"(.*?>)/g,
                (_, openingTag: string, src: string, closingTag: string) => {
                    const srcSet = generateSourceSet(src);
                    return `${openingTag} src="${src}" srcSet="${srcSet}" sizes="100vw" ${closingTag}`;
                },
            ) ?? '',
        [html],
    );
