import { Environment } from '../environment.global';

export const generateSourceSet = (
    src: string,
    sizes = [
        16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1920,
        2048, 3840,
    ],
    aspectRatio?: number,
) => {
    const isDirectusUrl = src.startsWith(Environment.DIRECTUS_API_URL);
    if (isDirectusUrl) {
        if (aspectRatio) {
            return sizes
                .map((width) => {
                    const height = Math.round(width / aspectRatio);
                    const transforms = [
                        [
                            'resize',
                            {
                                width,
                                height,
                                position: 'top',
                                withoutEnlargement: true,
                            },
                        ],
                    ];
                    return `${src}?transforms=${JSON.stringify(
                        transforms,
                    )}&format=webp&quality=75 ${width}w`;
                })
                .join(', ');
        } else {
            return sizes
                .map(
                    (width) =>
                        `${src}?width=${width}&withoutEnlargement=true&format=webp&quality=75 ${width}w`,
                )
                .join(', ');
        }
    } else {
        return src;
    }
};
