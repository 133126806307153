import { useEffect, useState } from 'react';

export enum Orientation {
    Landscape,
    Portrait,
}

export function useOrientation() {
    const parseOrientation = (
        screenOrientation: ScreenOrientation | undefined,
    ): Orientation => {
        if (!screenOrientation) {
            return Orientation.Portrait;
        }
        switch (screenOrientation.type) {
            case 'portrait-secondary':
            case 'portrait-primary':
                return Orientation.Portrait;
            case 'landscape-secondary':
            case 'landscape-primary':
                return Orientation.Landscape;
            default:
                return Orientation.Portrait;
        }
    };

    const [orientation, setOrientation] = useState<Orientation>(
        Orientation.Portrait,
    );

    useEffect(() => {
        const handleOrientationChange = () => {
            setOrientation(parseOrientation(window.screen.orientation));
        };
        window.addEventListener('orientationchange', handleOrientationChange);
        setOrientation(parseOrientation(window.screen.orientation));
        return () =>
            window.removeEventListener(
                'orientationchange',
                handleOrientationChange,
            );
    }, []);

    return {
        orientation,
    };
}
