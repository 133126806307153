import { HTMLHead } from '../html-head';
import { InterestPanel } from '@components/interest/interest-panel';
import { LoadingSpinner } from '@components/loading-spinner';
import { ReactComponent as Logo } from '@public/logo.svg';
import { Navigate } from 'react-router-dom';
import { NoData } from '@components/no-data-error';
import { ParsedInterest } from '@api/validations';
import { StorageServices } from '../services/storage';
import { useDirectus } from '../hooks/useDirectus';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';

function InterestsView() {
    const [checked, setChecked] = React.useState<ParsedInterest[]>([]);
    const [fetchedSelectedInterests, setFetchedSelectedInterests] =
        React.useState<boolean>(false);
    const [selectedInterests, setSelectedInterests] = React.useState<
        ParsedInterest[] | undefined
    >();
    const { t } = useTranslation('interest');
    const { data, loading } = useDirectus((directusClient) =>
        directusClient.getInterests(),
    );
    const navigate = useNavigate();
    useEffect(() => {
        StorageServices.getInterests()
            .then((interests) => {
                setSelectedInterests(interests);
            })
            .finally(() => setFetchedSelectedInterests(true));
    }, []);

    const handleChange = (
        selectedInterest: ParsedInterest,
        selected: boolean,
    ) => {
        if (selected) {
            setChecked((previouslyChecked) => [
                ...previouslyChecked,
                selectedInterest,
            ]);
        } else {
            setChecked((previouslyChecked) =>
                previouslyChecked.filter(
                    (interest) => interest.id !== selectedInterest.id,
                ),
            );
        }
    };
    const selectInterest = useCallback(() => {
        StorageServices.setInterests(checked)
            .then(() => {
                navigate('/termine', { replace: true });
            })
            .catch((e) => {
                console.dir(e);
            });
    }, [checked]);

    if (loading) {
        return (
            <div className="w-screen h-[55vh] mt-[72px] flex justify-center items-center">
                <LoadingSpinner />
            </div>
        );
    }
    if (!data) {
        return <NoData />;
    }

    if (!fetchedSelectedInterests) {
        return <></>;
    }

    return selectedInterests ? (
        <Navigate to="/termine" replace={true} />
    ) : (
        <div className="flex flex-col items-center h-screen pt-4 sm:h-fit">
            <HTMLHead title="Interessen" />
            <Logo className="w-50 h-auto mb-14" />
            <div className="uppercase w-full max-w-lg font-medium text-greyDark text-sm leading-6 mb-4 px-4">
                {t('info')}
            </div>
            <div className="px-4 max-w-lg grow w-full">
                <InterestPanel
                    data={data}
                    handleChange={handleChange}
                    checked={checked}
                />
            </div>
            <div className="w-full px-4 pt-4 pb-8 bg-white border-t border-border sm:border-0 sm:bg-opacity-0 sm:max-w-lg sm:mt-7">
                <button
                    className="w-full rounded-lg bg-rosenholz text-white text-sm leading-6 font-bold px-4 py-2"
                    onClick={selectInterest}
                    data-testid={'interestSaveButton'}
                    data-cy={'interestSaveButton'}
                >
                    {t('select')}
                </button>
            </div>
        </div>
    );
}

export default InterestsView;
