import { LoadingSpinner } from './loading-spinner';
import React, { MouseEventHandler, ReactElement } from 'react';

export const LoadingButton = ({
    onClick,
    isLoading,
    className = '',
    children,
    dataTestId,
    disabled,
}: React.PropsWithChildren<{
    onClick?: MouseEventHandler | (() => void);
    isLoading?: boolean;
    className?: string;
    dataTestId?: string;
    disabled?: boolean;
}>): ReactElement => {
    return (
        <button
            className={`${className} button-preset`}
            onClick={onClick}
            disabled={isLoading ?? disabled}
            data-testid={dataTestId}
        >
            {isLoading && <LoadingSpinner className="max-h-6" />}
            {!isLoading && children}
        </button>
    );
};
