import {
    CustomDirectusTypes,
    DonationAppeal,
    DonationReminder,
    Event,
    Interest,
    News,
} from '@api/types';
import {
    DONATION_APPEAL_FIELDS,
    DONATION_REMINDER_FIELDS,
    EVENT_FIELDS,
    EVENT_NOTIFICATION_FIELDS,
    NEWS_FIELDS,
} from '@utils/constants';
import { Directus, Filter, IDirectus } from '@directus/sdk';
import {
    DonationAppealSchema,
    DonationReminderSchema,
    EventSchema,
    IntercessionPricesSchema,
    InterestSchema,
    NewsSchema,
    NotificationEventSchema,
    ParseStripeTrackingData,
    ParsedDonationAppeal,
    ParsedDonationReminder,
    ParsedEvent,
    ParsedIntercessionPrices,
    ParsedInterest,
    ParsedNews,
    ParsedNotificationEvent,
    StripeTrackingDataSchema,
    tryParse,
} from '@api/validations';
import { Environment, isTest } from '../environment.global';
import { Order } from '@typesSrc/intercession-order';
import { StorageServices } from '../services/storage';
import { Stripe } from 'stripe';
import dayjs from '@utils/dayjs';

export type StripeData = {
    priceId: string;
    successRoute: string;
    cancelRoute: string;
    type: 'donation' | 'intercession';
    donationTitle?: string;
};

export class DirectusClient {
    private readonly client: IDirectus<CustomDirectusTypes>;

    constructor(
        providedDirectusClient?: IDirectus<CustomDirectusTypes>,
        accessToken?: string,
    ) {
        if (providedDirectusClient) {
            this.client = providedDirectusClient;
        } else {
            if (!Environment.DIRECTUS_API_URL) {
                throw new Error(
                    'Missing environment variable DIRECTUS_API_URL',
                );
            }
            const token = accessToken ?? Environment.DIRECTUS_API_ACCESS_TOKEN;
            if (!token) {
                throw new Error('Directus Client: Missing access token!');
            }
            // using client.auth.static() makes the client initialization async
            // that's why we prefer the staticToken option as a constructor argument
            // => our client is immediately ready to handle requests
            this.client = new Directus<CustomDirectusTypes>(
                Environment.DIRECTUS_API_URL,
                { auth: { staticToken: token } },
            );
        }
    }
    async getEvent(directus_id: string): Promise<ParsedEvent | undefined> {
        try {
            const { data } = await this.client.items('Event').readByQuery({
                filter: {
                    id: directus_id,
                },
                fields: EVENT_FIELDS,
            });
            if ((data?.length ?? 0) > 1) {
                console.warn(`Found multiple events for id ${directus_id}.`);
            }

            return data?.length
                ? tryParse(EventSchema, data[0] as Event)
                : undefined;
        } catch (e) {
            console.error(e);
        }
    }

    async getEventNotifications(): Promise<
        ParsedNotificationEvent[] | undefined
    > {
        const categoryFilter = await this.getCategoryFilter();
        try {
            const { data } = await this.client.items('Event').readByQuery({
                fields: EVENT_NOTIFICATION_FIELDS,
                filter: {
                    status: 'published',
                    notifications: {
                        // "are there some notifications with an id" -> same as "are there any notifications"
                        id: {
                            _nnull: true,
                        },
                        Notifications_id: {
                            date: {
                                // Current time in Berlin
                                _gte: dayjs().toISOString(),
                            },
                        },
                    },
                    ...categoryFilter,
                },
                limit: -1,
            });
            if (!data) {
                return [];
            }
            return (data as Event[])
                .map((d) => tryParse(NotificationEventSchema, d))
                .filter((d): d is ParsedNotificationEvent => !!d);
        } catch (e) {
            console.error(e);
        }
    }

    async getCategoryFilter() {
        const categoryFilter: Filter<Event> = {};
        const interests = await StorageServices.getInterests();
        if (interests && interests.length > 0) {
            // Load the interests, because the categories might have been updated on the server
            const loadedInterests =
                (await directusClient.getInterests()) ?? interests;
            const categoriesToFilter: string[] = [];
            interests.forEach((interest) => {
                loadedInterests
                    .find((i) => i.name === interest.name)
                    ?.related_categories?.forEach((category) => {
                        if (category) {
                            categoriesToFilter.push(category);
                        }
                    });
            });
            if (categoriesToFilter.length > 0) {
                categoryFilter.imported_event = {
                    category: {
                        _in: categoriesToFilter,
                    },
                };
            }
        }
        return categoryFilter;
    }

    async getAllEvents(
        offset: number,
        limit = 100,
    ): Promise<{ data: ParsedEvent[]; totalCount: number } | undefined> {
        const categoryFilter = await this.getCategoryFilter();
        try {
            const { data, meta } = await this.client
                .items('Event')
                .readByQuery({
                    fields: EVENT_FIELDS,
                    filter: {
                        status: 'published',
                        ...categoryFilter,
                    },
                    sort: ['imported_event.start_date' as keyof Event],
                    meta: 'total_count',
                    limit,
                    offset,
                });
            if (!data || !meta) {
                return { data: [], totalCount: 0 };
            }
            return {
                data: (data as Event[])
                    .map((d) => tryParse(EventSchema, d))
                    .filter((d): d is ParsedEvent => !!d),
                totalCount: meta.total_count ?? 0,
            };
        } catch (e) {
            console.error(e);
        }
    }

    async getAllDonationAppeals(): Promise<ParsedDonationAppeal[] | undefined> {
        try {
            const { data } = await this.client
                .items('DonationAppeal')
                .readByQuery({
                    fields: DONATION_APPEAL_FIELDS,
                    filter: {
                        status: 'published',
                    },
                });
            if (!data) {
                return [];
            }
            return (data as DonationAppeal[])
                .map((d) => tryParse(DonationAppealSchema, d))
                .filter((d): d is ParsedDonationAppeal => !!d);
        } catch (e) {
            console.error(e);
        }
    }

    async getAllDonationReminders(): Promise<
        ParsedDonationReminder[] | undefined
    > {
        try {
            const { data } = await this.client
                .items('DonationReminder')
                .readByQuery({
                    fields: DONATION_REMINDER_FIELDS,
                    filter: {
                        status: 'published',
                    },
                });
            if (!data) {
                return [];
            }
            return (data as DonationReminder[])
                .map((d) => tryParse(DonationReminderSchema, d))
                .filter((d): d is ParsedDonationReminder => !!d);
        } catch (e) {
            console.error(e);
        }
    }

    async getDonationAppeal(
        directus_id: string,
    ): Promise<ParsedDonationAppeal | undefined> {
        try {
            const { data } = await this.client
                .items('DonationAppeal')
                .readByQuery({
                    fields: DONATION_APPEAL_FIELDS,
                    filter: {
                        status: 'published',
                        id: directus_id,
                    },
                });
            if ((data?.length ?? 0) > 1) {
                console.warn(`Found multiple news for id ${directus_id}.`);
            }
            return data?.length
                ? tryParse(DonationAppealSchema, data[0] as DonationAppeal)
                : undefined;
        } catch (e) {
            console.error(e);
        }
    }

    async getAllNews(
        limit = -1,
        offset: number | undefined = undefined,
    ): Promise<ParsedNews[] | undefined> {
        try {
            const { data } = await this.client.items('News').readByQuery({
                fields: NEWS_FIELDS,
                sort: ['-date'],
                filter: {
                    status: 'published',
                },
                limit,
                offset,
            });
            if (!data) {
                return [];
            }
            return (data as News[])
                .map((d) => tryParse(NewsSchema, d))
                .filter((d): d is ParsedNews => !!d);
        } catch (e) {
            console.error(e);
        }
    }

    async getNews(directus_id: string): Promise<ParsedNews | undefined> {
        try {
            const { data } = await this.client.items('News').readByQuery({
                fields: NEWS_FIELDS,
                filter: {
                    status: 'published',
                    id: directus_id,
                },
            });
            if ((data?.length ?? 0) > 1) {
                console.warn(`Found multiple news for id ${directus_id}.`);
            }
            return data?.length
                ? tryParse(NewsSchema, data[0] as News)
                : undefined;
        } catch (e) {
            console.error(e);
        }
    }

    async getInterests(): Promise<ParsedInterest[] | undefined> {
        try {
            const { data } = await this.client.items('Interest').readByQuery({
                fields: ['id', 'name', 'related_categories'],
                limit: -1,
            });
            if (!data) {
                return [];
            }
            return (data as Interest[])
                .map((d) => tryParse(InterestSchema, d))
                .filter((d): d is ParsedInterest => !!d);
        } catch (e) {
            console.error(e);
        }
    }

    async checkoutDonation({
        priceId,
        successRoute,
        cancelRoute,
        type,
        donationTitle,
    }: StripeData & { type: 'donation' }): Promise<
        Stripe.Response<Stripe.Checkout.Session>
    > {
        const response = await this.client.transport.post(
            Environment.DIRECTUS_API_URL + '/stripe-checkout/donation',
            {
                price_id: priceId,
                success_url: encodeURI(
                    `${Environment.PUBLIC_URL}/#${successRoute}`,
                ),
                cancel_url: encodeURI(
                    `${Environment.PUBLIC_URL}/#${cancelRoute}`,
                ),
                type: type,
                donationTitle,
            },
        );
        return response.raw as Stripe.Response<Stripe.Checkout.Session>;
    }

    async checkoutIntercession({
        priceId,
        successRoute,
        cancelRoute,
        type,
        order,
    }: StripeData & { type: 'intercession' } & { order: Order }) {
        const response = await this.client.transport.post(
            Environment.DIRECTUS_API_URL + '/stripe-checkout/intercession',
            {
                price_id: priceId,
                success_url: `${Environment.PUBLIC_URL}/#${successRoute}`,
                cancel_url: `${Environment.PUBLIC_URL}/#${cancelRoute}`,
                type: type,
                ...order,
            },
        );
        return response.raw as Stripe.Response<Stripe.Checkout.Session>;
    }

    async getIntercessionPrices(): Promise<ParsedIntercessionPrices> {
        const response = await this.client.transport.get(
            Environment.DIRECTUS_API_URL +
                '/stripe-checkout/intercession-products',
        );

        return tryParse(IntercessionPricesSchema, response.raw, true);
    }

    async getTrackingData(
        stripeSessionId: string,
    ): Promise<ParseStripeTrackingData | undefined> {
        const response = await this.client.transport.get(
            `${Environment.DIRECTUS_API_URL}/stripe-checkout/stripe-tracking/${stripeSessionId}`,
        );
        return tryParse(StripeTrackingDataSchema, response.raw);
    }
}

export const directusClient = isTest
    ? new DirectusClient(
          new Directus<CustomDirectusTypes>(Environment.DIRECTUS_API_URL),
      )
    : new DirectusClient();
