import { ParsedInterest } from '@api/validations';
import { Preferences } from '@capacitor/preferences';

export const PREFERENCES_INTERESTS_KEY = 'interests';
export const PREFERENCES_LIVESTREAM_CONSENT_KEY = 'livestream_consent';
export const PREFERENCES_ANALYTICS_CONSENT_KEY = 'analytics_consent';
export const VIEW_COUNT_LIVESTREAM_KEY = 'livestream_count';
export const PREFERENCES_NOTIFICATIONS_CONSENT_KEY = 'notifications_consent';

export class StorageServices {
    static setInterests(interests: ParsedInterest[]): Promise<void> {
        return Preferences.set({
            key: PREFERENCES_INTERESTS_KEY,
            value: JSON.stringify(interests),
        });
    }

    static async getInterests() {
        const { value } = await Preferences.get({
            key: PREFERENCES_INTERESTS_KEY,
        });
        return value ? (JSON.parse(value) as ParsedInterest[]) : undefined;
    }

    static setLivestreamConsent(consent: boolean): Promise<void> {
        return Preferences.set({
            key: PREFERENCES_LIVESTREAM_CONSENT_KEY,
            value: `${consent}`,
        });
    }

    static async getLivestreamConsent(): Promise<boolean> {
        const { value } = await Preferences.get({
            key: PREFERENCES_LIVESTREAM_CONSENT_KEY,
        });
        return value === 'true';
    }

    static async getAnalyticsConsent(): Promise<boolean | undefined> {
        const { value } = await Preferences.get({
            key: PREFERENCES_ANALYTICS_CONSENT_KEY,
        });
        return value ? value === 'true' : undefined;
    }

    static async setAnalyticsConsent(consent: boolean): Promise<void> {
        return Preferences.set({
            key: PREFERENCES_ANALYTICS_CONSENT_KEY,
            value: `${consent}`,
        });
    }

    static async getLivestreamViewCount(): Promise<number> {
        const { value } = await Preferences.get({
            key: VIEW_COUNT_LIVESTREAM_KEY,
        });
        const returnValue = value ? parseInt(value) : 0;
        return isNaN(returnValue) ? 0 : returnValue;
    }

    static async setLivestreamViewCount(viewCount: number): Promise<void> {
        return Preferences.set({
            key: VIEW_COUNT_LIVESTREAM_KEY,
            value: `${viewCount}`,
        });
    }

    static async getNotificationConsent(): Promise<boolean | null> {
        const { value } = await Preferences.get({
            key: PREFERENCES_NOTIFICATIONS_CONSENT_KEY,
        });
        return value === null ? null : value === 'true';
    }

    static async setNotificationConsent(consent: boolean): Promise<void> {
        return Preferences.set({
            key: PREFERENCES_NOTIFICATIONS_CONSENT_KEY,
            value: `${consent}`,
        });
    }
}
