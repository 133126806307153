import { classNames } from '@utils/class-names';
import React from 'react';

export default function Tag({
    text,
    color,
    animate = false,
    dataTestId,
    dataCy,
}: {
    text: string;
    color: string;
    animate?: boolean;
    dataTestId?: string;
    dataCy?: string;
}) {
    return (
        <div
            data-cy={dataCy}
            data-testid={dataTestId}
            className={classNames(
                'rounded text-sm px-2 h-7 py-0.5 flex items-center',
                color,
                animate && 'motion-safe:animate-pulse',
            )}
        >
            <p className="text-white font-bold">{text}</p>
        </div>
    );
}
