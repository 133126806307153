import { ReactComponent as Info } from '@public/icons/info.svg';
import { ParsedHighlight } from '@api/validations';
import { useTranslation } from 'react-i18next';
import React from 'react';

export function EventHighlight({ highlight }: { highlight: ParsedHighlight }) {
    const { t } = useTranslation('highlight');

    return (
        <div
            className="bg-smaragd/10 text-smaragd mx-4 rounded-lg mb-2 flex flex-row items-center"
            data-cy="EventHighlight"
        >
            <Info className="m-[11px] mr-[7px]" />
            <p className="font-bold my-2 mr-3 leading-6">
                {highlight.title ?? t('title')}
            </p>
        </div>
    );
}
