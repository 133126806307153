import { ReactComponent as Checkmark } from '@public/icons/checkmark.svg';
import { DefaultTFuncReturn } from 'i18next';
import { classNames } from '@utils/class-names';
import React, { ReactElement } from 'react';

export default function Checkbox({
    checked,
    label,
    id,
    onChange,
}: {
    id: string;
    checked: boolean;
    label: string | ReactElement | DefaultTFuncReturn;
    onChange: (checked: boolean) => void;
}) {
    return (
        <>
            <input
                className="hidden"
                type={'checkbox'}
                id={id}
                checked={checked}
                onChange={(event) => onChange(event.target.checked)}
            />
            <label
                htmlFor={id}
                className="flex flex-row gap-2 items-center py-2"
            >
                <div
                    role="checkbox"
                    aria-checked={checked}
                    tabIndex={0}
                    className={classNames(
                        'w-[18px] h-[18px] inline-block rounded border-2 flex justify-items-center flex-center shrink-0',
                        checked
                            ? 'bg-smaragd border-smaragd'
                            : 'border-schiefer',
                    )}
                    onKeyDown={(event) =>
                        (event.key === ' ' || event.key === 'Enter') &&
                        onChange(!checked)
                    }
                >
                    {checked && (
                        <Checkmark
                            className="text-white"
                            width={13}
                            height={13}
                        />
                    )}
                </div>
                <div className="font-medium text-sm text-greyDark">{label}</div>
            </label>
        </>
    );
}
