import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import React from 'react';

export default function PaymentCanceled() {
    const { t } = useTranslation('intercession', { keyPrefix: 'cancelScreen' });
    return (
        <div className="h-450 flex justify-center">
            <div className="h-full max-w-sm flex flex-col mt-10 leading-6 mx-4 justify-start items-center text-center">
                <p className="text-greyDark pt-5 font-bold">{t('title')}</p>
                <Link to="/gebet/bestellung?resume=true">
                    <Button>{t('resumeButton')}</Button>
                </Link>
            </div>
        </div>
    );
}
