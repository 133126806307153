import { ReactComponent as ButtonTicket } from '@public/icons/ButtonTicket.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import Candle from '@public/icons/CandleIntercession.svg';
import React from 'react';

export default function Startscreen() {
    const { t } = useTranslation('intercession');
    return (
        <div className="h-450 flex justify-center">
            <div className="h-full max-w-sm flex flex-col mt-10 leading-6 mx-4 justify-start items-center text-center">
                <img
                    className="bg-white rounded-full w-40 aspect-square p-4"
                    src={Candle}
                    alt="Candle"
                />
                <p className="text-greyDark pt-5 font-bold">
                    {t('startscreen.text1')}
                </p>
                <p className="text-schiefer pt-5 text-sm leading-6 font-medium">
                    {t('startscreen.text2')}
                </p>
                <Link to={'bestellung'} className="w-full mt-auto mb-12">
                    <Button className="w-full">
                        <ButtonTicket className="mr-2" />{' '}
                        {t('startscreen.buttonText')}
                    </Button>
                </Link>
            </div>
        </div>
    );
}
