import { Outlet } from 'react-router-dom';
import { ViewsType } from '@typesSrc/views';
import BaseView from '@views/base-view';
import React from 'react';

function IntercessionView() {
    return (
        <BaseView title="Gebet" view={ViewsType.INTERCESSION}>
            <Outlet />
        </BaseView>
    );
}

export default IntercessionView;
