import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area';
import { useOrientation } from '../hooks/useOrientation';
import React, { ReactNode, useEffect, useState } from 'react';

const SafeSpaceContext = React.createContext<SafeAreaInsets | undefined>(
    undefined,
);
export default SafeSpaceContext;

export const SafeSpaceContextWrapper: React.FC<{
    children: ReactNode | undefined;
}> = (props) => {
    const [insets, setInsets] = useState<SafeAreaInsets | undefined>();
    const { orientation } = useOrientation();
    useEffect(() => {
        SafeArea.getSafeAreaInsets()
            .then((insets) => setInsets(insets))
            .catch((e) => console.error(e));
    }, [orientation]);

    return (
        <SafeSpaceContext.Provider value={insets}>
            {props.children}
        </SafeSpaceContext.Provider>
    );
};
