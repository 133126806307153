import { ParsedInterest } from '@api/validations';
import React, { ChangeEvent } from 'react';
import Toggle from 'react-toggle';

export const InterestPanel = ({
    data,
    handleChange,
    checked,
}: {
    data?: ParsedInterest[];
    handleChange: (interest: ParsedInterest, selected: boolean) => void;
    checked: ParsedInterest[];
}) => {
    return (
        <div className="flex flex-col grow space-y-3.5 justify-start w-full text-sm text-schiefer font-medium leading-6">
            {data?.map((interest) => (
                <React.Fragment key={`interest_${interest.id}`}>
                    <hr className="text-trennlinie" />
                    <label className="flex flex-row justify-between">
                        {interest.name}
                        <Toggle
                            className="mx-4"
                            checked={
                                !!checked.find(
                                    (checkedInterest) =>
                                        checkedInterest.id === interest.id,
                                )
                            }
                            icons={false}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                handleChange(interest, event.target.checked)
                            }
                            data-testid={`interest_${interest.id}`}
                            data-cy={`interest_${interest.id}`}
                        />
                    </label>
                </React.Fragment>
            ))}
        </div>
    );
};
