import { ViewsType } from '@typesSrc/views';
import { useNavigate, useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseView from '@views/base-view';
import React from 'react';

export default function ErrorView() {
    const navigate = useNavigate();
    const { t } = useTranslation('error');

    const error:
        | { status: number; statusText: string; message: string }
        | undefined = useRouteError() as
        | {
              status: number;
              statusText: string;
              message: string;
          }
        | undefined;

    return (
        <BaseView view={ViewsType.ERROR} title={'Error'} showHeader={false}>
            <div className="flex h-[75vh] flex-col justify-center items-center">
                <div className="text-3xl font-bold mb-7 text-greyDark">
                    {error && error.status === 404 ? (
                        <>{t('404.error')}</>
                    ) : (
                        <>{t('other.error')}</>
                    )}
                </div>
                <p className="mb-5 max-w-md text-center">
                    {error && error.status === 404 ? (
                        <>{t('404.details')}</>
                    ) : (
                        <>{t('other.details')}</>
                    )}
                </p>
                {error && (
                    <p>
                        <i>
                            {t('fehlercode')}: {error.status}
                        </i>
                    </p>
                )}
                <div className="justify-self-end max-w-md flex justify-center mt-10 w-full px-2">
                    <button
                        className="w-full rounded-lg bg-rosenholz text-white text-sm leading-6 font-bold px-4 py-2"
                        onClick={() => navigate('termine')}
                        data-testid={'interestSaveButton'}
                        data-cy={'interestSaveButton'}
                    >
                        {t('backButton')}
                    </button>
                </div>
            </div>
        </BaseView>
    );
}
