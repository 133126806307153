import {
    LocalNotificationSchema,
    PermissionStatus,
} from '@capacitor/local-notifications/dist/esm/definitions';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
    PREFERENCES_NOTIFICATIONS_CONSENT_KEY,
    StorageServices,
} from './storage';
import { Preferences } from '@capacitor/preferences';
import { directusClient } from '@api/directus-client';

export enum NotificationType {
    EVENT = 'event',
    DONATION_REMINDER = 'donationReminder',
}

export class NotificationService {
    static async requestNotification(): Promise<void> {
        const permission = await LocalNotifications.checkPermissions();
        let permissionAfterRequest: PermissionStatus = {
            display: permission.display,
        };
        if (
            permission.display !== 'granted' &&
            permission.display !== 'denied'
        ) {
            permissionAfterRequest =
                await LocalNotifications.requestPermissions();
        }
        if (
            (await StorageServices.getNotificationConsent()) === null &&
            permissionAfterRequest.display === 'granted'
        ) {
            await StorageServices.setNotificationConsent(true);
        }
    }

    static async scheduleNotifications(
        notifications: LocalNotificationSchema[],
    ): Promise<void> {
        if (await StorageServices.getNotificationConsent()) {
            await NotificationService.requestNotification();

            // LocalNotifications.schedule throws an error on iOS when passed notifications in the past. Make sure we only pass dates in the future.
            const now = new Date();
            let futureNotifications = notifications.filter(
                (n) => (n.schedule?.at ?? now) > now,
            );
            if (futureNotifications.length > 64) {
                console.error(
                    'found more than 64 notifications to schedule. Continue to only schedule 64 upcoming events',
                );
            }
            futureNotifications = futureNotifications.slice(0, 64);
            await LocalNotifications.schedule({
                notifications: futureNotifications,
            });
        }
    }

    static async clearAllPendingNotifications(): Promise<void> {
        const pending = await LocalNotifications.getPending();
        if (pending.notifications.length > 0) {
            await LocalNotifications.cancel({
                notifications: pending.notifications,
            });
        }
    }

    static async clearPendingNotificationsForNotificationType(
        notificationType: NotificationType,
    ): Promise<void> {
        const pending = await LocalNotifications.getPending();
        const relevantNotifications = pending.notifications.filter(
            (notification) =>
                (notification.extra as { type: NotificationType | undefined })
                    .type === notificationType,
        );
        if (relevantNotifications.length > 0) {
            await LocalNotifications.cancel({
                notifications: relevantNotifications,
            });
        }
    }

    static async scheduleEventNotifications(): Promise<void> {
        const consent = (
            await Preferences.get({
                key: PREFERENCES_NOTIFICATIONS_CONSENT_KEY,
            })
        ).value;
        if (consent !== 'true') {
            return;
        }
        const events = await directusClient.getEventNotifications();
        if (!events) {
            return;
        }
        await NotificationService.clearPendingNotificationsForNotificationType(
            NotificationType.EVENT,
        );
        const notifications = events
            .map((event) =>
                event.notifications.map((notification) => ({
                    title: notification.title,
                    body: notification.description ?? '',
                    id: notification.id,
                    //TODO: Add image, once supported: https://github.com/ionic-team/capacitor-plugins/issues/430
                    //attachments: [
                    //    {
                    //        id: `${notification.id}`,
                    //        url: '',
                    //    },
                    //],
                    extra: {
                        route: `/termine/${event.id}`,
                        type: NotificationType.EVENT,
                    },
                    schedule: {
                        at: notification.date.toDate(),
                        allowWhileIdle: true,
                    },
                })),
            )
            .flat()
            .sort((a, b) => {
                return b.schedule.at.getTime() - a.schedule.at.getTime();
            });
        await NotificationService.scheduleNotifications(notifications);
    }
}
