import {
    BaseSchema,
    InferType,
    array,
    boolean,
    mixed,
    number,
    object,
    string,
} from 'yup';
import { DeliveryMethod } from '@typesSrc/intercession-order';
import {
    EventHighlight,
    EventNotifications,
    Highlight,
    Notifications,
} from '@api/types';
import dayjs, { Dayjs } from '@utils/dayjs';

function tryParse<T extends BaseSchema>(
    schema: T,
    data: any,
    strict: true,
): InferType<T>;
function tryParse<T extends BaseSchema>(
    schema: T,
    data: any,
    strict: false,
): InferType<T> | undefined;
function tryParse<T extends BaseSchema>(
    schema: T,
    data: any,
): InferType<T> | undefined;
function tryParse<T extends BaseSchema>(
    schema: T,
    data: any,
    strict = false,
): InferType<T> | undefined {
    try {
        schema.validateSync(data);
    } catch (validationError) {
        console.error(
            `Invalid data:`,
            validationError,
            JSON.stringify(data, null, 2),
        );
        if (strict) {
            throw validationError;
        }
    }
    // Even if validations fail, we still want to try to parse the data
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return schema.cast(data, {
        stripUnknown: true,
        assert: true,
    });
}

export { tryParse };

export type DirectusImage = InferType<typeof ImageSchema>;
export const ImageSchema = object({
    id: string().required(),
    title: string().nullable().defined(),
    width: number().required(),
    height: number().required(),
});

const dateTimeTransformFunction = (value: string) => {
    return dayjs(value);
};

export const ImportedEventSchema = object({
    id: number().required(),
    category: string(),
    subcategory: string(),
    title: string(),
    description: string(),
    short_description: string(),
    start_date: mixed<Dayjs>().required().transform(dateTimeTransformFunction),
    end_date: mixed<Dayjs>().required().transform(dateTimeTransformFunction),
    subtitle: string(),
    persons: object({
        Prediger: array(string()),
        Liturg: array(string()),
        Solisten: array(string()),
        Chor: array(string()),
        Orchester: array(string()),
        Ensemble: array(string()),
        Leitung: array(string()),
        Organist: array(string()),
        Übersetzer: array(string()),
        Kindergottestdienst: array(string()),
    }),
    abendmahl: boolean(),
    image: string().nullable().defined(),
    shop_url: string().nullable().defined(),
});

export enum PublicationStatus {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
}

export type ParsedHighlight = {
    title?: string;
    pinned: boolean;
};
export const HighlightSchema = object({
    title: string().optional(),
    pinned: boolean(),
}).transform(function (_: ParsedHighlight, originalValue: EventHighlight) {
    const highlight = originalValue.Highlight_id as Highlight;
    return {
        title: highlight.text,
        pinned: highlight.pin_to_top,
    };
});

export type ParsedNotification = {
    id: number;
    date: Dayjs;
    title: string;
    description?: string;
    link?: string;
};
export const NotificationSchema = object({
    id: number().required(),
    date: mixed<Dayjs>().required().transform(dateTimeTransformFunction),
    title: string().required(),
    description: string().nullable().defined(),
    link: string().nullable().defined(),
}).transform(function (_: any, originalValue: EventNotifications) {
    return originalValue.Notifications_id as Notifications;
});

export type ParsedEvent = InferType<typeof EventSchema>;
export const EventSchema = object({
    id: number().required(),
    imported_event: ImportedEventSchema.required(),
    price: number().nullable().defined(),
    is_streamed: boolean().required(),
    status: mixed<PublicationStatus>()
        .oneOf(Object.values(PublicationStatus))
        .required(),
    highlights: array(HighlightSchema).required(),
    // coupons: string(),
});

export type ParsedNotificationEvent = InferType<typeof NotificationEventSchema>;
export const NotificationEventSchema = object({
    id: number().required(),
    notifications: array(NotificationSchema).required(),
});

export type ParsedNews = InferType<typeof NewsSchema>;
export const NewsSchema = object({
    id: number().required(),
    status: mixed<PublicationStatus>()
        .oneOf(Object.values(PublicationStatus))
        .required(),
    teaser_text: string().required(),
    title: string().required(),
    teaser_image: string().nullable().defined(),
    content: string().required(),
    date: mixed<Dayjs>()
        .nullable()
        .defined()
        .transform(dateTimeTransformFunction),
});

export type ParsedDonationAppeal = InferType<typeof DonationAppealSchema>;
export const DonationAppealSchema = object({
    id: number().required(),
    status: mixed<PublicationStatus>()
        .oneOf(Object.values(PublicationStatus))
        .required(),
    title: string().required(),
    description: string().required(),
    image: ImageSchema.nullable().defined(),
    linkWithBill: string().nullable().defined(),
    linkWithoutBill: string().nullable().defined(),
});

export type ParsedDonationReminder = InferType<typeof DonationReminderSchema>;
export const DonationReminderSchema = object({
    id: number().required(),
    status: mixed<PublicationStatus>()
        .oneOf(Object.values(PublicationStatus))
        .required(),
    title: string().nullable().defined(),
    description: string().nullable().defined(),
    num_livestreams: number().required(),
    max_donated_cents: number().required(),
    donation_link: string().nullable().defined(),
    suggested_donation_amount_cents: number().nullable().defined(),
});

export type ParsedInterest = InferType<typeof InterestSchema>;
export const InterestSchema = object({
    id: number().required(),
    name: string().required(),
    related_categories: array(string()),
});

export type ParsedIntercessionPrices = InferType<
    typeof IntercessionPricesSchema
>;
const PriceObject = object({
    price: number().required(),
    stripe_id: string().required(),
});
export const IntercessionPricesSchema = object({
    [DeliveryMethod.DIGITAL]: PriceObject.required(),
    [DeliveryMethod.PHYSICAL]: PriceObject.required(),
}).transformKeys((key) => {
    if (key === 'digital') {
        return DeliveryMethod.DIGITAL;
    }
    if (key === 'physical') {
        return DeliveryMethod.PHYSICAL;
    }
    return key;
});

export type ParseStripeTrackingData = InferType<
    typeof StripeTrackingDataSchema
>;
export const StripeTrackingDataSchema = object({
    amount: number().required(),
    donationCardTitle: string().required(),
});
