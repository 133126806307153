import { classNames } from '@utils/class-names';
import React, { useEffect, useRef } from 'react';

export function DynamicText(props: { className: string; text: string }) {
    const textArea = useRef<HTMLTextAreaElement>(null);
    const [overflows, setOverflows] = React.useState(false);
    useEffect(() => {
        setOverflows(
            !!textArea.current &&
                textArea.current.scrollHeight > textArea.current.clientHeight,
        );
    }, [textArea]);
    return (
        <div className="relative h-full w-full">
            <textarea
                className={classNames(
                    props.className,
                    'w-full h-full scrollbar-hide border-none outline-none resize-none',
                    overflows && 'pb-8',
                )}
                readOnly
                ref={textArea}
                defaultValue={props.text}
            />
            {overflows && (
                <div className="content-[''] absolute -bottom-1 right-0 bg-gradient-to-t from-white to-transparent w-full h-8" />
            )}
        </div>
    );
}
