import { CheckoutState, useCheckout } from '../../hooks/useCheckout';
import { Link, useSearchParams } from 'react-router-dom';
import { Order } from '@typesSrc/intercession-order';
import { ParsedIntercessionPrices } from '@api/validations';
import { TransitionGroup } from 'react-transition-group';
import { ViewsType } from '@typesSrc/views';
import { Wizard } from 'react-use-wizard';
import { useDirectus } from '../../hooks/useDirectus';
import { useTranslation } from 'react-i18next';
import AnimatedStep from '@components/intercession/animated-step';
import BaseView from '@views/base-view';
import Button from '@components/button';
import FullscreenModalView from '@views/fullscreen-modal-view';
import PersonalInformationStep from '@components/intercession/personal-information-step';
import React, {
    MutableRefObject,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import ReceiverDetailsStep from '@components/intercession/receiver-details-step';
import SelectDeliveryStep from '@components/intercession/select-delivery-step';
import SelectTimingStep from '@components/intercession/select-timing-step';
import WizardHeader from '@components/intercession/wizard-header';

export type IntercessionOrderStep = {
    isValid: (order: Partial<Order>) => boolean;
    ReactComponent: (props: {
        order: Partial<Order>;
        onOrderChanged: (newOrder: Partial<Order>) => void;
        index?: number;
        fixedContainer: MutableRefObject<HTMLDivElement | null>;
        onSubmit: () => void;
        animating: boolean;
        prices?: ParsedIntercessionPrices;
        loadingPrices: boolean;
    }) => React.ReactElement;
};

const SAVED_ORDER_KEY = 'SAVED_ORDER';

export default function () {
    const [order, setOrder] = useState<Partial<Order>>({});
    const updateOrder = useCallback(
        (newOrder: Partial<Order>) =>
            setOrder((oldOrder) => ({ ...oldOrder, ...newOrder })),
        [setOrder],
    );
    const [search] = useSearchParams();
    const dataExists = () => {
        return Object.keys(order).length !== 0;
    };

    useEffect(() => {
        if (search.get('resume')) {
            setOrder(
                JSON.parse(
                    window.sessionStorage.getItem(SAVED_ORDER_KEY) ?? '{}',
                ) as Partial<Order>,
            );
        }
    }, []);

    const steps: IntercessionOrderStep[] = useMemo(
        () => [
            SelectDeliveryStep,
            ReceiverDetailsStep,
            SelectTimingStep,
            PersonalInformationStep,
        ],
        [],
    );

    const {
        triggerCheckout,
        state: checkoutState,
        reset: resetCheckout,
    } = useCheckout();
    const onSubmit = useCallback(() => {
        if (!steps.every(({ isValid }) => isValid(order))) {
            return;
        }
        window.sessionStorage.setItem(SAVED_ORDER_KEY, JSON.stringify(order));
        triggerCheckout({
            order: order as Order,
            type: 'intercession',
            priceId: order.priceId!,
            cancelRoute: '/gebet/abbruch',
            successRoute: '/gebet/erfolg',
        });
    }, [...steps, order, triggerCheckout]);

    const { t } = useTranslation('intercession');

    const {
        data: prices,
        error: loadPriceError,
        loading: loadingPrices,
    } = useDirectus((directusClient) => directusClient.getIntercessionPrices());

    const WizardHeaderProps = {
        dataExists: dataExists(),
        stepIsValidFunctions: steps.map(
            ({ isValid }) =>
                () =>
                    isValid(order),
        ),
    };

    let errorOverlay: ReactElement | undefined;
    if (loadPriceError) {
        errorOverlay = (
            <div className="flex flex-col items-center justify-center h-full px-4">
                <div className="text-xl font-bold mb-5">
                    {t('errorNotAvailable.title')}
                </div>
                <p className="mb-5">{t('errorNotAvailable.message')}</p>
                <Link to="/">
                    <Button>{t('errorNotAvailable.backButton')}</Button>
                </Link>
            </div>
        );
    }

    if (checkoutState === CheckoutState.ERROR) {
        errorOverlay = (
            <div className="flex flex-col items-center justify-center h-full px-4">
                <div className="text-xl font-bold mb-5">
                    {t('errorDuringCheckout.title')}
                </div>
                <p className="mb-5">{t('errorDuringCheckout.message')}</p>
                <Button onClick={resetCheckout} data-testid="retryBtn">
                    {t('errorDuringCheckout.retryButton')}
                </Button>
            </div>
        );
    }

    return (
        <>
            {/* Keep the Wizard mounted even though an overlay may be shown.
             This keeps the current step active after closing the overlay */}
            {errorOverlay && (
                <FullscreenModalView>
                    <BaseView
                        view={ViewsType.INTERCESSION}
                        title={'Error'}
                        showHeader={false}
                    >
                        {errorOverlay}
                    </BaseView>
                </FullscreenModalView>
            )}
            <Wizard
                header={<WizardHeader {...WizardHeaderProps} />}
                wrapper={<TransitionGroup component={null} />}
            >
                {steps.map(({ ReactComponent }, index) => (
                    <AnimatedStep
                        key={index}
                        index={index}
                        childProps={{
                            order,
                            onOrderChanged: updateOrder,
                            prices,
                            loadingPrices,
                            onSubmit,
                        }}
                        childFn={(props) => (
                            <div className="max-w-lg mx-auto px-4">
                                <ReactComponent {...props} />
                            </div>
                        )}
                    />
                ))}
            </Wizard>
        </>
    );
}
