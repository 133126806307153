import { DirectusClient, directusClient } from '@api/directus-client';
import { useCallback, useEffect, useRef, useState } from 'react';

export function usePaginatedDirectus<T>(
    fetchFunction: (
        directusClient: DirectusClient,
        offset: number,
        pageSize: number,
    ) => Promise<{ data: T[]; totalCount: number } | undefined>,
    pageSize: number,
): {
    data: T[] | undefined;
    hasMore: boolean;
    error: string | null;
    loading: boolean;
    fetchMoreData: () => Promise<void>;
} {
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState<T[] | undefined>(undefined);
    const [error, setError] = useState<string | null>(null); //TODO: add proper error class
    const loading = useRef(false);

    const fetchMoreData = useCallback(async () => {
        if (loading.current) {
            return;
        }
        loading.current = true;
        try {
            const result = await fetchFunction(
                directusClient,
                offset,
                pageSize,
            );
            /* istanbul ignore next */
            if (!result) {
                setHasMore(false);
                return;
            }
            const { data, totalCount } = result;

            setHasMore(totalCount > offset + pageSize && !!data);

            setData((current) => [...(current ?? []), ...data]);
            setOffset(offset + pageSize);
        } catch (error) {
            setError(error as string);
        } finally {
            loading.current = false;
        }
    }, [offset, pageSize]);

    useEffect(() => {
        void fetchMoreData();
    }, []);

    return { data, error, loading: loading.current, fetchMoreData, hasMore };
}
