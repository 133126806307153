export enum DeliveryMethod {
    DIGITAL = 'DIGITAL',
    PHYSICAL = 'PHYSICAL',
}

export enum DigitalDeliveryMethod {
    EMAIL = 'EMAIL',
    MOBILE = 'MOBILE',
}

export enum Timing {
    SUNDAY_SERVICE = 'SUNDAY_SERVICE',
    NOONTIME_PRAYER = 'NOONTIME_PRAYER',
}

export type Order = {
    receiverName: string;
    message: string;
    deliveryMethod: DeliveryMethod;
    digitalDeliveryMethod: DigitalDeliveryMethod;
    receiverStreet: string;
    receiverAdditionalAddress: string;
    receiverCity: string;
    receiverZip: string;
    receiverEmail: string;
    receiverPhone: string;
    timing: Timing;
    senderFirstName: string;
    senderLastName: string;
    contactSenderViaEmail: boolean;
    senderEmail: string;
    contactSenderViaPhone: boolean;
    senderPhoneNumber: string;
    termsOfServiceAccepted: boolean;
    cancellationWaiverAccepted: boolean;
    subscribeToNewsletter: boolean;
    price: number;
    priceId: string;
};
