export const DIRECTUS_DATE_PATTERN = 'YYYY-MM-DD';
export const DIRECTUS_TIME_PATTERN = DIRECTUS_DATE_PATTERN + 'HH:mm:ss';
export const EURO_PRICE_FORMAT = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
});
export const NEWS_BATCH_SIZE = 10;

export const HEADER_HEIGHT_IN_PX = 72;
export const FOOTER_HEIGHT_IN_PX = 99;

export const DONATION_EVENT_ID = 1000000;

export const TEN_MINUTES_IN_MS = 10000 * 60;

export const NEWS_FIELDS = [
    'id',
    'status',
    'teaser_image',
    'teaser_text',
    'title',
    'content',
    'date',
];

export const EVENT_FIELDS = [
    'id',
    'price',
    'is_streamed',
    'status',
    'imported_event.id',
    'imported_event.category',
    'imported_event.title',
    'imported_event.short_description',
    'imported_event.start_date',
    'imported_event.end_date',
    'imported_event.subtitle',
    'imported_event.description',
    'imported_event.image',
    'imported_event.persons',
    'imported_event.shop_url',
    'highlights.Highlight_id.id',
    'highlights.Highlight_id.name',
    'highlights.Highlight_id.pin_to_top',
    'highlights.Highlight_id.text',
];

export const EVENT_NOTIFICATION_FIELDS = [
    'id',
    'status',
    'notifications.Notifications_id.id',
    'notifications.Notifications_id.date',
    'notifications.Notifications_id.title',
    'notifications.Notifications_id.description',
    'notifications.Notifications_id.link',
];

export const DONATION_APPEAL_FIELDS = [
    'id',
    'status',
    'title',
    'description',
    'image',
    'image.id',
    'image.title',
    'image.width',
    'image.height',
    'linkWithBill',
    'linkWithoutBill',
];

export const DONATION_REMINDER_FIELDS = [
    'id',
    'status',
    'num_livestreams',
    'title',
    'max_donated_cents',
    'donation_link',
    'suggested_donation_amount_cents',
    'description',
];
