import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import React from 'react';

export default function NavWarning({ onDismiss }: { onDismiss: () => void }) {
    const { t } = useTranslation('intercession');
    const navigate = useNavigate();
    return (
        <div
            data-testid="nav-warning"
            className="fixed z-20 w-screen h-screen bg-white bg-opacity-30 flex justify-center items-center"
        >
            <div className="w-80 p-5 bg-white border-border border rounded-lg shadow-card flex justify-center items-center flex-col">
                <p className="font-bold mb-2">{t('navWarning.title')}</p>
                <p>{t('navWarning.details1')}</p>
                <p className="self-start">{t('navWarning.details2')}</p>
                <Button
                    data-testid="dismiss-button"
                    className="mt-5 w-full bg-messing"
                    onClick={onDismiss}
                >
                    {t('navWarning.button2')}
                </Button>
                <Button
                    data-testid="confirm-back-button"
                    className="mt-5 w-full "
                    onClick={() => navigate('/')}
                >
                    {t('navWarning.button')}
                </Button>
            </div>
        </div>
    );
}
