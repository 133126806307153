export const isProd = process.env.NODE_ENV === 'production';
export const isDev = process.env.NODE_ENV === 'development';
export const isTest = process.env.NODE_ENV === 'test';
export const isDebug = !!process.env.DEBUG;
export const isCI = !!process.env.CI;

export function definedOrThrow(variableName: string, value?: string): string {
    /* istanbul ignore next */
    if (!value) {
        throw new Error(
            `Missing environment variable process.env.${variableName}, you need to provide it for the application to run.`,
        );
    }
    return value;
}

// We need to access the environment variables statically with process.env.__NAME__
// since the value is replaced at build-time and needs to appear in the script verbatim.
// See https://create-react-app.dev/docs/adding-custom-environment-variables/.
export const Environment = {
    DIRECTUS_API_URL: definedOrThrow(
        'REACT_APP_DIRECTUS_API_URL',
        process.env.REACT_APP_DIRECTUS_API_URL,
    ),
    DIRECTUS_API_ACCESS_TOKEN: definedOrThrow(
        'REACT_APP_DIRECTUS_API_ACCESS_TOKEN',
        process.env.REACT_APP_DIRECTUS_API_ACCESS_TOKEN,
    ),
    PUBLIC_URL: definedOrThrow(
        'REACT_APP_PUBLIC_URL',
        process.env.REACT_APP_PUBLIC_URL,
    ),
};
