import { DirectusImage } from '@api/validations';
import { DynamicText } from '@components/dynamic-text';
import { useNavigate } from 'react-router';
import Image from '@components/image';
import React from 'react';

export type DonationAppealCardData = {
    title: string;
    description: string;
    image?: DirectusImage;
    scrollToCard: (id: number) => void;
    activeCard: number;
    index: number;
    directusID: number;
};

function DonationAppealCard({
    title,
    description,
    image,
    scrollToCard,
    activeCard,
    index,
    directusID,
}: DonationAppealCardData) {
    const navigate = useNavigate();
    return (
        <button
            className="snap-center border border-lightGray shadow-card snap-always bg-white pb-4 rounded-lg w-[calc(100vw-64px)] h-full flex flex-col last:!mr-4 overflow-y-hidden"
            data-cy={`DonationCard${index}`}
            data-testid={`DonationCard${index}`}
            onClick={() => {
                if (index !== activeCard) {
                    scrollToCard(index);
                } else {
                    navigate(`/foerdern/${directusID}`);
                }
            }}
        >
            {image && (
                <Image
                    asset={image}
                    alt={title}
                    className="rounded-t-lg h-1/2 max-h-52 w-full object-cover sm:max-h-[35vh]"
                    aspectRatio={2}
                />
            )}
            <div className="text-start px-5 pt-4 w-full grow flex flex-col">
                <p className="font-bold pb-1 text-lg sm:text-2xl sm:mb-5">
                    {title}
                </p>
                <DynamicText
                    className="font-medium text-greyDark leading-6 grow whitespace-pre-line"
                    text={description}
                />
            </div>
        </button>
    );
}

export default DonationAppealCard;
