import { ReactComponent as ChevronLeft } from '@public/icons/chevron_left.svg';
import { FOOTER_HEIGHT_IN_PX } from '@utils/constants';
import { Link, useParams } from 'react-router-dom';
import { LoadingSpinner } from '@components/loading-spinner';
import { NoData } from '@components/no-data-error';
import { Orientation, useOrientation } from '../hooks/useOrientation';
import { ViewsType } from '@typesSrc/views';
import { useDirectus } from '../hooks/useDirectus';
import BaseView from '@views/base-view';
import FullscreenModalView from '@views/fullscreen-modal-view';
import Image from '../components/image';
import React, { useContext } from 'react';
import SafeSpaceContext from '../context/safe-space-context';
import useOptimizedImages from '../hooks/useOptimizedImages';

function NewsView() {
    const { newsId } = useParams();
    const { data, loading } = useDirectus((directusClient) => {
        if (newsId) {
            return directusClient.getNews(newsId);
        }
        return Promise.resolve(undefined);
    });
    const safeSpace = useContext(SafeSpaceContext);
    const { orientation } = useOrientation();

    const optimizedHtml = useOptimizedImages(data?.content);

    if (loading) {
        return (
            <BaseView
                title={`Neuigkeit`}
                view={ViewsType.NEWS}
                showHeader={false}
            >
                <div className="w-screen h-[55vh] mt-[72px] flex justify-center items-center">
                    <LoadingSpinner />
                </div>
            </BaseView>
        );
    }

    if (!data) {
        return (
            <FullscreenModalView>
                <BaseView
                    title={`Neuigkeit`}
                    view={ViewsType.NEWS}
                    showHeader={true}
                >
                    <NoData />
                </BaseView>
            </FullscreenModalView>
        );
    }

    return (
        <FullscreenModalView>
            <BaseView
                title={`Neuigkeit ${data.title}`}
                view={ViewsType.NEWS}
                showHeader={false}
            >
                <div
                    className="relative bg-background"
                    style={{
                        paddingTop: `${safeSpace?.insets.top ?? 0}px`,
                    }}
                >
                    <Link
                        to="/neuigkeiten"
                        className="absolute bg-white left-4 p-4 rounded-full h-10 w-10 flex justify-center items-center"
                        data-cy="newsBackButton"
                        data-testid="newsBackButton"
                        style={{
                            top:
                                data.teaser_image &&
                                orientation === Orientation.Portrait
                                    ? `${Math.max(
                                          16 + (safeSpace?.insets.top ?? 0),
                                          56,
                                      )}px`
                                    : `${16 + (safeSpace?.insets.top ?? 0)}px`,
                        }}
                    >
                        <ChevronLeft />
                    </Link>
                    {data.teaser_image ? (
                        <Image
                            src={data.teaser_image}
                            alt={data.teaser_image}
                            className="h-auto w-full object-cover max-h-60 sm:max-h-[40vh] phoneLandscape:h-20 mx-auto"
                            data-cy="newsThumbnail"
                            aspectRatio={4 / 3}
                        />
                    ) : (
                        <div
                            style={{
                                height: `${
                                    64 + (safeSpace?.insets.top ?? 0)
                                }px`,
                            }}
                        />
                    )}
                </div>
                <div className="flex justify-center mx-4 mt-4">
                    <div className="grow max-w-2xl">
                        <div className="pb-5 border-b-[1px] border-border font-medium">
                            <p className="text-3xl">{data.title}</p>
                            {data.date?.isValid() && (
                                <p className="mt-3 text-sm text-schiefer">
                                    {data.date.format('DD.MM.YYYY')}
                                </p>
                            )}
                        </div>
                        <div className="mt-5 pb-36">
                            <div
                                className="imported"
                                dangerouslySetInnerHTML={{
                                    __html: optimizedHtml,
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className="fixed w-full pt-4 pointer-events-none"
                    style={{ bottom: `${FOOTER_HEIGHT_IN_PX}px` }}
                >
                    <div className="relative top-px bg-gradient-to-t from-white w-full min-h-[90px] phoneLandscape:min-h-[0px]" />
                </div>
            </BaseView>
        </FullscreenModalView>
    );
}

export default NewsView;
