import { ReactComponent as ButtonTicket } from '@public/icons/ButtonTicket.svg';
import { IntercessionOrderStep } from '@components/intercession/intercession-order';
import {
    MAX_LENGTH_DEFAULT_STRING,
    MAX_LENGTH_PHONE_NUMBER,
    ValidationResult,
    isValidEmail,
    isValidPhoneNumber,
    isValidString,
    validateEmail,
    validatePhoneNumber,
    validateString,
} from '@utils/validation';
import { Order } from '@typesSrc/intercession-order';
import { Trans, useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import Button from '@components/button';
import Checkbox from '@components/forms/checkbox';
import FormHeadline from '@components/forms/headline';
import React, { MutableRefObject, useRef } from 'react';
import TextInput from '@components/forms/text-input';
import useFocusNextEmpyInput from '../../hooks/useFocusNextEmpyInput';
import useProgrammaticFocus from '../../hooks/useProgrammaticFocus';

function RenderPersonalInformationStep({
    order,
    onOrderChanged,
    fixedContainer,
    onSubmit,
    animating,
}: {
    order: Partial<Order>;
    onOrderChanged: (newOrder: Partial<Order>) => void;
    fixedContainer: MutableRefObject<HTMLDivElement | null>;
    onSubmit: () => void;
    animating: boolean;
}) {
    const { t } = useTranslation('intercession', {
        keyPrefix: 'personalInformation',
    });

    const [refInputPhone, focusPhoneInput] =
        useProgrammaticFocus<HTMLInputElement>();
    const [refInputEmail, focusEmailInput] =
        useProgrammaticFocus<HTMLInputElement>();
    const refSenderFirstName = useRef<HTMLInputElement>();
    const refSenderLastName = useRef<HTMLInputElement>();
    useFocusNextEmpyInput(
        [refSenderFirstName, refSenderLastName, refInputPhone, refInputEmail],
        !animating,
    );

    return (
        <>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    onSubmit();
                }}
            >
                <div className="pb-[90px]">
                    <FormHeadline>{t('headline')}</FormHeadline>
                    <div className="flex flex-row gap-4 mt-4 mb-4">
                        <div className="grow">
                            <TextInput
                                value={order.senderFirstName ?? ''}
                                inputTestId="senderFirstName"
                                ref={refSenderFirstName}
                                onChange={(firstName) =>
                                    onOrderChanged({
                                        senderFirstName: firstName,
                                    })
                                }
                                title={`${t('firstNameTitle')}*`}
                                name="firstname"
                                maxLength={MAX_LENGTH_DEFAULT_STRING}
                                validate={validateString}
                            />
                        </div>
                        <div className="grow">
                            <TextInput
                                value={order.senderLastName ?? ''}
                                inputTestId="senderLastName"
                                ref={refSenderLastName}
                                onChange={(lastName) =>
                                    onOrderChanged({ senderLastName: lastName })
                                }
                                title={`${t('lastNameTitle')}*`}
                                name="lastname"
                                maxLength={MAX_LENGTH_DEFAULT_STRING}
                                validate={validateString}
                            />
                        </div>
                    </div>
                    <Checkbox
                        id="email"
                        checked={order.contactSenderViaEmail ?? false}
                        label={t('emailCheckboxLabel')}
                        onChange={(contactSenderViaEmail) => {
                            onOrderChanged({ contactSenderViaEmail });
                            if (contactSenderViaEmail) {
                                focusEmailInput();
                            }
                        }}
                    />
                    {order.contactSenderViaEmail && (
                        <TextInput
                            value={order.senderEmail ?? ''}
                            inputTestId="senderEmail"
                            onChange={(email) =>
                                onOrderChanged({ senderEmail: email })
                            }
                            title={`${t('emailTitle')}*`}
                            type="email"
                            name="email"
                            ref={refInputEmail}
                            classNameContainer="mb-2"
                            maxLength={MAX_LENGTH_DEFAULT_STRING}
                            validate={validateEmail}
                            errorMessages={{
                                [ValidationResult.WRONG_FORMAT]:
                                    'emailWrongFormat',
                            }}
                        />
                    )}
                    <h4 className="text-greyDark text-xs mb-1">{t('or')}</h4>
                    <Checkbox
                        id="phone"
                        checked={order.contactSenderViaPhone ?? false}
                        label={t('mobileCheckboxLabel')}
                        onChange={(contactSenderViaPhone) => {
                            onOrderChanged({ contactSenderViaPhone });
                            if (contactSenderViaPhone) {
                                focusPhoneInput();
                            }
                        }}
                    />
                    {order.contactSenderViaPhone && (
                        <TextInput
                            value={order.senderPhoneNumber ?? ''}
                            inputTestId="senderPhoneNumber"
                            onChange={(phoneNumber) =>
                                onOrderChanged({
                                    senderPhoneNumber: phoneNumber,
                                })
                            }
                            title={`${t('mobileTitle')}*`}
                            type="tel"
                            name="phone"
                            ref={refInputPhone}
                            maxLength={MAX_LENGTH_PHONE_NUMBER}
                            validate={validatePhoneNumber}
                        />
                    )}
                    <div className="mt-6">
                        <Checkbox
                            id="tos"
                            checked={order.termsOfServiceAccepted ?? false}
                            label={
                                <>
                                    <Trans
                                        t={t}
                                        i18nKey="tosCheckboxLabel"
                                        components={{
                                            tosLink: (
                                                // content is added by the Trans component
                                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                                <a
                                                    target="_blank"
                                                    className="text-rosenholz underline"
                                                    href={t('tosLink') ?? '#'}
                                                    rel="noreferrer"
                                                />
                                            ),
                                        }}
                                    />
                                    *
                                </>
                            }
                            onChange={(termsOfServiceAccepted) =>
                                onOrderChanged({ termsOfServiceAccepted })
                            }
                        />
                        <Checkbox
                            id="waiver"
                            checked={order.cancellationWaiverAccepted ?? false}
                            label={`${t('cancellationWaiverLabel')}*`}
                            onChange={(cancellationWaiverAccepted) =>
                                onOrderChanged({ cancellationWaiverAccepted })
                            }
                        />
                        <Checkbox
                            id="newsletter"
                            checked={order.subscribeToNewsletter ?? false}
                            label={t('newsletterCheckboxLabel')}
                            onChange={(subscribeToNewsletter) =>
                                onOrderChanged({ subscribeToNewsletter })
                            }
                        />
                        <div className="text-schiefer text-sm mt-4">
                            {t('requiredInfo')}
                        </div>
                    </div>
                    {/* Provide a submit input to make <Enter> work in the TextInputs. (the Button below is not actually part of the form) */}
                    <input type="submit" hidden />
                    {fixedContainer.current &&
                        createPortal(
                            <div className="absolute pointer-events-auto bottom-0 left-0 w-full bg-white h-[90px] p-4">
                                <Button
                                    className="w-full"
                                    onClick={onSubmit}
                                    disabled={!isValid(order)}
                                    data-testid="buyButton"
                                >
                                    <ButtonTicket className="mr-2 inline" />{' '}
                                    {t('buyButton', {
                                        price: (order.price ?? 0) / 100,
                                    })}
                                </Button>
                            </div>,
                            fixedContainer.current,
                        )}
                </div>
            </form>
        </>
    );
}

function isValid(order: Partial<Order>) {
    // One contact method has to be selected (selecting both is possible)
    const isContactMethodSelected =
        !!order.contactSenderViaEmail || !!order.contactSenderViaPhone;
    // If selected, the email must be valid
    const isEmailValid =
        !order.contactSenderViaEmail || isValidEmail(order.senderEmail);
    // If selected, the phone number must be valid
    const isPhoneValid =
        !order.contactSenderViaPhone ||
        isValidPhoneNumber(order.senderPhoneNumber);

    return !!(
        isValidString(order.senderFirstName) &&
        isValidString(order.senderLastName) &&
        order.termsOfServiceAccepted &&
        order.cancellationWaiverAccepted &&
        isContactMethodSelected &&
        isEmailValid &&
        isPhoneValid
    );
}

const PersonalInformationStep: IntercessionOrderStep = {
    ReactComponent: RenderPersonalInformationStep,
    isValid,
};

export default PersonalInformationStep;
