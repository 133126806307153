import 'dayjs/locale/de';
// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
// eslint-disable-next-line no-restricted-imports
export { Dayjs } from 'dayjs';

dayjs.locale('de');

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault(process.env.TZ ?? 'Europe/Berlin');

/**
 * This patched version of dayjs will format times in Europe/Berlin timezone.
 *
 * #Examples
 *
 * ##Formatting
 * ```
 * import dayjs from '@shared/dayjs';
 * dayjs(<input>).format();
 * ```
 *
 * ##Formatting in a different timezone
 * ```
 * import dayjs from '@shared/dayjs';
 * dayjs(<input>).tz('America/New_York').format();
 * ```
 *
 * ##Parsing in Berlin-local time
 * `dayjs.tz(<input>, 'Europe/Berlin')`
 *
 * ##Parsing properly formatted input with timestamp
 * `dayjs(<input>)`
 */
const dayjsWithDefaultTimezone = ((
    ...args: Parameters<typeof dayjs>
): ReturnType<typeof dayjs> => {
    return dayjs(...args).tz('Europe/Berlin');
}) as typeof dayjs;
Object.assign(dayjsWithDefaultTimezone, dayjs);

export default dayjsWithDefaultTimezone;
