import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { matomo } from '../html-head';
import { useDirectus } from '../hooks/useDirectus';
import { useQuery } from '../hooks/useQuery';
import Angel from '@public/icons/Angel.svg';
import Button from '@components/button';
import React, { useEffect } from 'react';

export default function Success({
    translations = 'intercession',
    link = '/',
    donationTitle = '',
}: {
    translations?: string;
    link?: string;
    donationTitle?: string;
}) {
    const { t } = useTranslation(translations, { keyPrefix: 'success' });
    const query = useQuery();
    const sessionId = query.get('session_id');
    const { data } = useDirectus(async (client) => {
        return sessionId ? await client.getTrackingData(sessionId) : null;
    });
    useEffect(() => {
        if (data) {
            matomo([
                'trackEvent',
                'Fördern',
                'Gespendet',
                `Spendenzweck: ${data.donationCardTitle}`,
                data.amount,
            ]);
        }
    }, [data]);
    return (
        <div className="h-450 mb-10 flex justify-center">
            <div className="h-full max-w-sm flex flex-col pt-10 leading-6 px-4 justify-start items-center">
                <img
                    className="bg-white rounded-full w-40 aspect-square p-4"
                    src={Angel}
                    alt="Engel"
                />
                <p className="w-full text-greyDark mt-10 font-bold">
                    {t('text1')}
                </p>
                <p className="text-schiefer mt-5 mb-7 text-sm leading-6 font-medium">
                    {donationTitle ? (
                        <Trans
                            t={t}
                            i18nKey="text2"
                            values={{ name: donationTitle }}
                        />
                    ) : (
                        t('text2')
                    )}
                </p>
                <Link to={link} className="mt-auto w-full">
                    <Button className="w-full">{t('buttonText')}</Button>
                </Link>
            </div>
        </div>
    );
}
