import { DirectusClient, directusClient } from '@api/directus-client';
import { useEffect, useState } from 'react';

export function useDirectus<T>(
    fetchFunction: (directusClient: DirectusClient) => Promise<T>,
): { data: T | undefined; error: string | null; loading: boolean } {
    const [data, setData] = useState<T | undefined>(undefined);
    const [error, setError] = useState<string | null>(null); //TODO: add proper error class
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetchFunction(directusClient);
                setData(response);
            } catch (error) {
                setError(error as string);
            }
            setLoading(false);
        }
        void fetchData();
    }, []);

    return { data, error, loading };
}
