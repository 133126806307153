import {
    DeliveryMethod,
    DigitalDeliveryMethod,
    Order,
} from '@typesSrc/intercession-order';
import { IntercessionOrderStep } from '@components/intercession/intercession-order';
import { ReactComponent as Letter } from '@public/icons/letter.svg';
import {
    MAX_LENGTH_DEFAULT_STRING,
    MAX_LENGTH_MESSAGE,
    MAX_LENGTH_PHONE_NUMBER,
    MAX_LENGTH_ZIP,
    ValidationResult,
    isValidEmail,
    isValidGermanZipCode,
    isValidMessage,
    isValidOptionalString,
    isValidPhoneNumber,
    isValidString,
    validateEmail,
    validateGermanZipCode,
    validateMessage,
    validateOptionalString,
    validatePhoneNumber,
    validateString,
} from '@utils/validation';
import { ReactComponent as Phone } from '@public/icons/phone.svg';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';
import Button from '@components/button';
import Chooser from '@components/forms/chooser';
import FormHeadline from '@components/forms/headline';
import React, {
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import TextInput from '@components/forms/text-input';
import useFocusNextEmpyInput from '../../hooks/useFocusNextEmpyInput';
import useProgrammaticFocus from '../../hooks/useProgrammaticFocus';

function RenderReceiverDetailsStep({
    order,
    onOrderChanged,
    fixedContainer,
    animating,
}: {
    order: Partial<Order>;
    onOrderChanged: (newOrder: Partial<Order>) => void;
    fixedContainer: MutableRefObject<HTMLDivElement | null>;
    animating: boolean;
}) {
    const { nextStep } = useWizard();
    const { t } = useTranslation('intercession', {
        keyPrefix: 'receiverDetails',
    });
    const submit = useCallback(() => {
        if (isValid(order)) {
            void nextStep();
        }
    }, [order]);
    const [refInputPhone, focusPhoneInput] =
        useProgrammaticFocus<HTMLInputElement>();
    const [refInputEmail, focusEmailInput] =
        useProgrammaticFocus<HTMLInputElement>();
    const refReceiverName = useRef<HTMLInputElement>();
    const refMessage = useRef<HTMLInputElement>();
    const refReceiverStreet = useRef<HTMLInputElement>();
    const refReceiverAdditionalAddress = useRef<HTMLInputElement>();
    const refReceiverZip = useRef<HTMLInputElement>();
    const refReceiverCity = useRef<HTMLInputElement>();
    const [showNewlinesMessage, setShowNewlinesMessage] = useState(false);

    useFocusNextEmpyInput(
        [
            refReceiverName,
            refMessage,
            refInputPhone,
            refInputEmail,
            refReceiverStreet,
            refReceiverAdditionalAddress,
            refReceiverZip,
            refReceiverCity,
        ],
        !animating,
    );

    const sanitizeMessage = useCallback(() => {
        const sanitizedMessage = getSanitizedMessage(order);
        if (sanitizedMessage && sanitizedMessage !== order.message) {
            onOrderChanged({
                message: sanitizedMessage.trim(),
            });
            setShowNewlinesMessage(true);
        }
    }, [order.message, order.digitalDeliveryMethod, order.deliveryMethod]);

    useEffect(sanitizeMessage, [
        order.digitalDeliveryMethod,
        order.deliveryMethod,
    ]);

    return (
        <>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    submit();
                }}
            >
                <div className="pb-[90px]">
                    <FormHeadline>
                        {order.deliveryMethod === DeliveryMethod.DIGITAL
                            ? t('variantDigital')
                            : t('variantPhysical')}
                    </FormHeadline>
                    <FormHeadline>{t('headline')}</FormHeadline>
                    <TextInput
                        classNameContainer="mb-6"
                        type={'text'}
                        inputTestId="inputReceiverName"
                        value={order.receiverName ?? ''}
                        onChange={(receiverName) =>
                            onOrderChanged({ receiverName })
                        }
                        title={`${t('receiverNameTitle')}*`}
                        placeholder={t('receiverNamePlaceholder')}
                        ref={refReceiverName}
                        maxLength={MAX_LENGTH_DEFAULT_STRING}
                        validate={validateString}
                    />
                    <TextInput
                        classNameContainer="mb-4"
                        classNameInput="h-36"
                        type={'textarea'}
                        inputTestId="inputMessage"
                        value={order.message ?? ''}
                        onChange={(message) => {
                            onOrderChanged({ message });
                            setShowNewlinesMessage(false);
                        }}
                        title={`${t('messageTitle')}*`}
                        placeholder={t('messagePlaceholder')}
                        ref={refMessage}
                        maxLength={MAX_LENGTH_MESSAGE}
                        validate={validateMessage}
                        onBlur={sanitizeMessage}
                        additionalErrorMessage={
                            showNewlinesMessage && t('newlinesRemovedMessage')
                        }
                    />
                    {order.deliveryMethod === DeliveryMethod.DIGITAL ? (
                        <>
                            <Chooser
                                className="mb-6"
                                direction="horizontal"
                                options={[
                                    {
                                        title: t('digital.email'),
                                        image: (
                                            <Letter className="text-rosenholz" />
                                        ),
                                        value: DigitalDeliveryMethod.EMAIL,
                                    },
                                    {
                                        title: t('digital.mobile'),
                                        image: (
                                            <Phone className="text-rosenholz" />
                                        ),
                                        value: DigitalDeliveryMethod.MOBILE,
                                    },
                                ]}
                                selected={order.digitalDeliveryMethod ?? null}
                                onSelect={(digitalDeliveryMethod) => {
                                    onOrderChanged({ digitalDeliveryMethod });
                                    switch (digitalDeliveryMethod) {
                                        case DigitalDeliveryMethod.EMAIL:
                                            focusEmailInput();
                                            break;
                                        case DigitalDeliveryMethod.MOBILE:
                                            focusPhoneInput();
                                            break;
                                    }
                                }}
                            />
                            {order.digitalDeliveryMethod ===
                                DigitalDeliveryMethod.EMAIL && (
                                <TextInput
                                    type="email"
                                    inputTestId="inputReceiverEmail"
                                    value={order.receiverEmail ?? ''}
                                    onChange={(receiverEmail) =>
                                        onOrderChanged({ receiverEmail })
                                    }
                                    classNameContainer="scroll-mb-[90px]"
                                    title={`${t('digital.receiverEmail')}*`}
                                    ref={refInputEmail}
                                    maxLength={MAX_LENGTH_DEFAULT_STRING}
                                    validate={validateEmail}
                                    errorMessages={{
                                        [ValidationResult.WRONG_FORMAT]:
                                            'emailWrongFormat',
                                    }}
                                />
                            )}
                            {order.digitalDeliveryMethod ===
                                DigitalDeliveryMethod.MOBILE && (
                                <TextInput
                                    type="tel"
                                    inputTestId="inputReceiverPhone"
                                    value={order.receiverPhone ?? ''}
                                    onChange={(receiverPhone) =>
                                        onOrderChanged({ receiverPhone })
                                    }
                                    classNameContainer="scroll-mb-[90px]"
                                    title={`${t('digital.receiverPhone')}*`}
                                    ref={refInputPhone}
                                    maxLength={MAX_LENGTH_PHONE_NUMBER}
                                    validate={validatePhoneNumber}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <TextInput
                                classNameContainer="mb-6"
                                type={'text'}
                                inputTestId="inputReceiverStreet"
                                ref={refReceiverStreet}
                                value={order.receiverStreet ?? ''}
                                onChange={(street) =>
                                    onOrderChanged({ receiverStreet: street })
                                }
                                title={`${t('physical.streetTitle')}*`}
                                placeholder={t('physical.streetPlaceholder')}
                                maxLength={MAX_LENGTH_DEFAULT_STRING}
                                validate={validateString}
                            />
                            <TextInput
                                classNameContainer="mb-6"
                                type={'text'}
                                inputTestId="inputReceiverAdditionalAddress"
                                ref={refReceiverAdditionalAddress}
                                value={order.receiverAdditionalAddress ?? ''}
                                onChange={(additionalAddress) =>
                                    onOrderChanged({
                                        receiverAdditionalAddress:
                                            additionalAddress,
                                    })
                                }
                                title={t('physical.additionalAddressTitle')}
                                placeholder={t(
                                    'physical.additionalAddressPlaceholder',
                                )}
                                maxLength={MAX_LENGTH_DEFAULT_STRING}
                                validate={validateOptionalString}
                            />
                            <div className="flex flex-row gap-4">
                                <div>
                                    <TextInput
                                        classNameContainer="mb-6"
                                        type={'text'}
                                        inputTestId="inputReceiverZip"
                                        ref={refReceiverZip}
                                        value={order.receiverZip ?? ''}
                                        onChange={(zip) =>
                                            onOrderChanged({ receiverZip: zip })
                                        }
                                        title={`${t('physical.zipTitle')}*`}
                                        placeholder={t(
                                            'physical.zipPlaceholder',
                                        )}
                                        maxLength={MAX_LENGTH_ZIP}
                                        validate={validateGermanZipCode}
                                        errorMessages={{
                                            [ValidationResult.WRONG_FORMAT]:
                                                'zipWrongFormat',
                                        }}
                                    />
                                </div>
                                <div className="grow">
                                    <TextInput
                                        classNameContainer="mb-6"
                                        type={'text'}
                                        inputTestId="inputReceiverCity"
                                        ref={refReceiverCity}
                                        value={order.receiverCity ?? ''}
                                        onChange={(city) =>
                                            onOrderChanged({
                                                receiverCity: city,
                                            })
                                        }
                                        title={`${t('physical.cityTitle')}*`}
                                        placeholder={t(
                                            'physical.cityPlaceholder',
                                        )}
                                        maxLength={MAX_LENGTH_DEFAULT_STRING}
                                        validate={validateString}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="text-schiefer text-sm mt-4">
                        {t('requiredInfo')}
                    </div>
                </div>
                {/* Provide a submit input to make <Enter> work in the TextInputs. (the Button below is not actually part of the form) */}
                <input type="submit" hidden />
                {fixedContainer.current &&
                    createPortal(
                        <div className="absolute pointer-events-auto bottom-0 left-0 w-full bg-white h-[90px] p-4">
                            <Button
                                className="w-full"
                                disabled={!isValid(order)}
                                onClick={submit}
                                data-testid="nextButton"
                            >
                                {t('nextButton')}
                            </Button>
                        </div>,
                        fixedContainer.current,
                    )}
            </form>
        </>
    );
}

function getSanitizedMessage(order: Partial<Order>): string | undefined | null {
    if (
        order.message &&
        order.deliveryMethod === DeliveryMethod.DIGITAL &&
        order.digitalDeliveryMethod === DigitalDeliveryMethod.MOBILE
    ) {
        // Replace newlines (and surrounding whitespace) with a single space
        return order.message.replaceAll(/\s*\n\s*/g, ' ');
    }
    return order.message;
}

function isValid(order: Partial<Order>) {
    return (
        isValidString(order.receiverName) &&
        isValidMessage(order.message) &&
        getSanitizedMessage(order) === order.message &&
        ((order.deliveryMethod === DeliveryMethod.DIGITAL &&
            order.digitalDeliveryMethod === DigitalDeliveryMethod.MOBILE &&
            isValidPhoneNumber(order.receiverPhone)) ||
            (order.digitalDeliveryMethod === DigitalDeliveryMethod.EMAIL &&
                isValidEmail(order.receiverEmail)) ||
            (order.deliveryMethod === DeliveryMethod.PHYSICAL &&
                isValidString(order.receiverStreet) &&
                isValidOptionalString(order.receiverAdditionalAddress) &&
                isValidGermanZipCode(order.receiverZip) &&
                isValidString(order.receiverCity)))
    );
}

const ReceiverDetailsStep: IntercessionOrderStep = {
    ReactComponent: RenderReceiverDetailsStep,
    isValid,
};

export default ReceiverDetailsStep;
