import { HTMLHead } from '../../html-head';
import { classNames } from '@utils/class-names';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';
import Header from '@components/header/header';
import NavWarning from '@components/intercession/nav-warning';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import SafeSpaceContext from '../../context/safe-space-context';

export default function WizardHeader({
    dataExists,
    stepIsValidFunctions,
}: {
    dataExists: boolean;
    stepIsValidFunctions: (() => boolean)[];
}) {
    const safeSpace = useContext(SafeSpaceContext);
    const { activeStep, stepCount, goToStep, previousStep } = useWizard();
    const stepArray = Array(stepCount).fill(0);
    const [maxStep, setMaxStep] = useState(activeStep);
    const { t } = useTranslation('intercession');
    const [showBackConfirmation, setShowBackConfirmation] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setMaxStep((prevMaxStep) => Math.max(prevMaxStep, activeStep));
    }, [activeStep]);

    const goBack = useCallback(() => {
        if (activeStep === 0) {
            if (!dataExists) {
                navigate('/');
                return;
            }
            setShowBackConfirmation(true);
        } else {
            void previousStep();
        }
    }, [activeStep]);

    return (
        <>
            {showBackConfirmation && (
                <NavWarning onDismiss={() => setShowBackConfirmation(false)} />
            )}
            <HTMLHead
                title={t('titleOrder', {
                    step: activeStep + 1,
                    totalSteps: stepCount,
                })}
            />

            <div
                className="fixed top-0 w-full z-10 bg-white bg-opacity-20 backdrop-blur-md"
                style={{ height: `${safeSpace?.insets.top ?? 0}px` }}
            ></div>

            <Header
                title={t('title')}
                onBack={goBack}
                showBack
                showSettings={false}
            />
            <div className="flex justify-between mb-6 mt-2">
                {stepArray.map((_, index) => {
                    const stepIsValid = stepIsValidFunctions[index]
                        ? stepIsValidFunctions[index]()
                        : true;
                    const stepIsFutureStep = index > maxStep;
                    const stepIsCurrentStep = index === activeStep;
                    const errorState =
                        !stepIsValid && !stepIsFutureStep && !stepIsCurrentStep;

                    return (
                        <div key={index} className="w-full relative">
                            <div
                                className={classNames(
                                    'w-full border-t absolute top-5',
                                    index <= activeStep && 'border-smaragd',
                                    index > activeStep && 'border-lightGray',
                                    index === 0 && 'w-1/2 right-0',
                                    index === stepCount - 1 && 'w-1/2 left-0',
                                )}
                            />
                            <button
                                className={classNames(
                                    'relative h-10 w-10 rounded-full flex items-center justify-center font-bold text-sm mx-auto',
                                    index <= activeStep &&
                                        'bg-smaragd text-white',
                                    index > activeStep &&
                                        'bg-white text-schiefer border border-lightGray',
                                )}
                                onClick={() => {
                                    goToStep(index);
                                }}
                                disabled={stepIsFutureStep}
                                data-testid={`stepIndicator-${index + 1}`}
                                data-state={
                                    index === activeStep
                                        ? 'active'
                                        : errorState
                                        ? 'error'
                                        : 'inactive'
                                }
                            >
                                {index + 1}
                                {errorState && (
                                    <div className="absolute h-4 w-4 -top-1 -right-1 rounded-full bg-rosenholz text-white font-bold text-xs">
                                        !
                                    </div>
                                )}
                            </button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
