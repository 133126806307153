import { Outlet } from 'react-router-dom';
import { ViewsType } from '@typesSrc/views';
import BaseView from '@views/base-view';
import DonationOverview from '@components/donations/donation-overview';
import React from 'react';

function DonationView() {
    return (
        <BaseView
            view={ViewsType.DONATION}
            title="Spendenseite"
            scrollable={false}
        >
            <DonationOverview />
            <Outlet />
        </BaseView>
    );
}

export default DonationView;
