import { DeliveryMethod, Order } from '@typesSrc/intercession-order';
import { IntercessionOrderStep } from '@components/intercession/intercession-order';
import { ParsedIntercessionPrices } from '@api/validations';
import { ValidationResult, validateEnum } from '@utils/validation';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';
import Card from '@components/card/card';
import FormHeadline from '@components/forms/headline';
import IntercessionProductImageDigital from '@public/intercession_product_digital.png';
import IntercessionProductImagePostcard from '@public/intercession_product_postcard.png';
import React from 'react';

function RenderSelectDeliveryStep({
    onOrderChanged,
    order,
    prices,
    loadingPrices,
}: {
    order: Partial<Order>;
    onOrderChanged: (newOrder: Partial<Order>) => void;
    prices?: ParsedIntercessionPrices;
    loadingPrices: boolean;
}) {
    const { nextStep } = useWizard();
    const { t } = useTranslation('intercession', {
        keyPrefix: 'deliverySelection',
    });

    return (
        <>
            <FormHeadline>{t('headline')}</FormHeadline>
            <button
                className="text-left"
                data-testid="selectDigital"
                onClick={() => {
                    onOrderChanged({
                        deliveryMethod: DeliveryMethod.DIGITAL,
                        price: prices![DeliveryMethod.DIGITAL].price,
                        priceId: prices![DeliveryMethod.DIGITAL].stripe_id,
                    });
                    setTimeout(() => void nextStep(), 100);
                }}
                disabled={loadingPrices}
            >
                <Card
                    teaserImageUrl={IntercessionProductImageDigital}
                    title={t('digital.title')}
                    price={(prices?.[DeliveryMethod.DIGITAL]?.price ?? 0) / 100}
                    description={t('digital.description')}
                    selected={order.deliveryMethod === DeliveryMethod.DIGITAL}
                    loadingPrice={loadingPrices}
                    imageHeight="fixed"
                />
            </button>
            <button
                data-testid="selectPhysical"
                className="text-left"
                onClick={() => {
                    onOrderChanged({
                        deliveryMethod: DeliveryMethod.PHYSICAL,
                        price: prices![DeliveryMethod.PHYSICAL].price,
                        priceId: prices![DeliveryMethod.PHYSICAL].stripe_id,
                    });
                    setTimeout(() => void nextStep(), 100);
                }}
                disabled={loadingPrices}
            >
                <Card
                    teaserImageUrl={IntercessionProductImagePostcard}
                    title={t('physical.title')}
                    price={
                        (prices?.[DeliveryMethod.PHYSICAL]?.price ?? 0) / 100
                    }
                    description={t('physical.description')}
                    selected={order.deliveryMethod === DeliveryMethod.PHYSICAL}
                    loadingPrice={loadingPrices}
                    imageHeight="fixed"
                />
            </button>
        </>
    );
}

function isValid(order: Partial<Order>) {
    return (
        validateEnum(order.deliveryMethod, DeliveryMethod) ===
        ValidationResult.VALID
    );
}

const SelectDeliveryStep: IntercessionOrderStep = {
    ReactComponent: RenderSelectDeliveryStep,
    isValid,
};

export default SelectDeliveryStep;
