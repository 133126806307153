import { ValidationResult, ValidatorFn } from '@utils/validation';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import React, {
    MutableRefObject,
    forwardRef,
    useCallback,
    useState,
} from 'react';

export type TextInputProps = {
    type?:
        | 'text'
        | 'textarea'
        | 'email'
        | 'number'
        | 'password'
        | 'search'
        | 'submit'
        | 'tel'
        | 'url';
    name?: string;
    value: string;
    onChange: (value: string) => void;
    title: string;
    placeholder?: string;
    classNameContainer?: string;
    classNameInput?: string;
    inputTestId?: string;
    maxLength?: number;
    validate?: ValidatorFn;
    errorMessages?: Partial<Record<ValidationResult, string>>;
    onBlur?: () => void;
    additionalErrorMessage?: string | null | false;
};

const useValidation = (
    validateFn?: ValidatorFn,
    messageKeys: Partial<
        Record<Exclude<ValidationResult, ValidationResult.VALID>, string>
    > = {},
    maxLength?: number,
): [
    isValid: boolean,
    errorMessage: string,
    runValidation: (value: string) => void,
] => {
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation('formValidation');

    const validate = useCallback(
        (value: string) => {
            if (validateFn) {
                const validationResult = validateFn(value);
                let newErrorMessage = errorMessage;
                switch (validationResult) {
                    case ValidationResult.VALID:
                        break;
                    case ValidationResult.EMPTY:
                        newErrorMessage = t(
                            messageKeys[ValidationResult.EMPTY] ?? 'empty',
                        );
                        break;
                    case ValidationResult.TOO_LONG:
                        newErrorMessage = t(
                            messageKeys[ValidationResult.TOO_LONG] ?? 'tooLong',
                            {
                                length: value.length,
                                maxLength,
                            },
                        );
                        break;
                    case ValidationResult.WRONG_FORMAT:
                        newErrorMessage = t(
                            messageKeys[ValidationResult.WRONG_FORMAT] ??
                                'wrongFormat',
                        );
                        break;
                }
                setErrorMessage(newErrorMessage);
                setIsValid(validationResult === ValidationResult.VALID);
            }
        },
        [validateFn, messageKeys],
    );
    return [isValid, errorMessage, validate];
};

const TextInput = forwardRef<
    HTMLTextAreaElement | HTMLInputElement | null | undefined,
    TextInputProps
>(
    (
        {
            type = 'text',
            value,
            onChange: onChangeFn,
            placeholder,
            title,
            classNameContainer,
            classNameInput,
            name,
            inputTestId,
            validate: validateFn,
            maxLength,
            errorMessages = {},
            onBlur,
            additionalErrorMessage,
        },
        ref,
    ) => {
        const textBoxStyle =
            'px-4 py-3 rounded-md border border-lightGray w-full text-greyDark';

        const [isValid, errorMessage, runValidation] = useValidation(
            validateFn,
            errorMessages,
            maxLength,
        );

        const onChange = useCallback(
            (
                event:
                    | React.ChangeEvent<HTMLTextAreaElement>
                    | React.ChangeEvent<HTMLInputElement>,
            ) => {
                onChangeFn(event.target.value);
                if (!isValid) {
                    runValidation(event.target.value);
                }
            },
            [isValid, runValidation, onChangeFn],
        );

        const canShowErrorMessage = !!maxLength || !!validateFn;
        const showErrorMessage =
            !!(maxLength && value.length > maxLength * 0.9) ||
            !isValid ||
            additionalErrorMessage;
        return (
            <>
                {title && (
                    <h4 className="text-greyDark text-xs mb-1">{title}</h4>
                )}
                <div className={classNameContainer}>
                    {type === 'textarea' ? (
                        <textarea
                            className={classNames(
                                textBoxStyle,
                                'resize-none h-full',
                                classNameInput,
                            )}
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            name={name}
                            ref={ref as MutableRefObject<HTMLTextAreaElement>}
                            data-testid={inputTestId}
                            onBlur={(event) => {
                                runValidation(event.target.value);
                                onBlur?.();
                            }}
                        />
                    ) : (
                        <input
                            className={classNames(
                                textBoxStyle,
                                classNameInput,
                                'mb-1',
                            )}
                            type={type}
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            name={name}
                            ref={ref as MutableRefObject<HTMLInputElement>}
                            data-testid={inputTestId}
                            onBlur={(event) => {
                                runValidation(event.target.value);
                                onBlur?.();
                            }}
                        />
                    )}
                    {canShowErrorMessage && (
                        <span
                            className={classNames(
                                'block tracking-tighter text-right text-schiefer text-xs mb-1 opacity-0 transition-opacity',
                                showErrorMessage && 'opacity-100',
                                !isValid && 'font-bold !text-rosenholz',
                            )}
                        >
                            {/* This zero-width-space prevents the height from collapsing if there is no error message */}
                            &#8203;
                            {[
                                isValid &&
                                    maxLength &&
                                    value.length > maxLength * 0.9 &&
                                    `${value.length}/${maxLength}`,
                                !isValid && errorMessage,
                                additionalErrorMessage
                                    ? additionalErrorMessage
                                    : '',
                            ]
                                .filter(Boolean)
                                .join(' ')}
                        </span>
                    )}
                </div>
            </>
        );
    },
);

export default TextInput;
