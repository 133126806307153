import { HTMLHead } from '../html-head';
import { ViewsType } from '@typesSrc/views';
import { classNames } from '@utils/class-names';
import Footer from '@components/footer/footer';
import Header from '@components/header/header';
import React, {
    PropsWithChildren,
    useCallback,
    useContext,
    useRef,
} from 'react';
import SafeSpaceContext from '../context/safe-space-context';

export default function BaseView({
    title,
    view,
    stickyTitle = false,
    showHeader = true,
    children,
    showBack = false,
    showSettings = true,
    scrollable = true,
    enableScrollToTopBehavior = false,
    showFooter = true,
    onBack,
}: PropsWithChildren<{
    title: string;
    view: ViewsType;
    stickyTitle?: boolean;
    showHeader?: boolean;
    showBack?: boolean;
    showSettings?: boolean;
    scrollable?: boolean;
    enableScrollToTopBehavior?: boolean;
    showFooter?: boolean;
    onBack?: () => void;
}>) {
    const scrollContainer = useRef<HTMLDivElement>(null);
    const safeSpace = useContext(SafeSpaceContext);

    /* istanbul ignore next: covered in cypress tests */
    const scrollToTop = useCallback(() => {
        if (scrollContainer.current && enableScrollToTopBehavior) {
            scrollContainer.current.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [scrollContainer, enableScrollToTopBehavior]);

    return (
        <div className="font-base bg-background flex flex-col justify-items-stretch h-screen min-h-screen">
            <HTMLHead title={title} />

            <div
                className="fixed top-0 w-full z-10 bg-white bg-opacity-20 backdrop-blur-md"
                style={{ height: `${safeSpace?.insets.top ?? 0}px` }}
            ></div>

            {showHeader && stickyTitle && (
                <Header
                    title={view}
                    showBack={showBack}
                    showSettings={showSettings}
                    onBack={onBack}
                />
            )}
            <div
                className={classNames(
                    scrollable
                        ? 'flex-1 overflow-y-scroll overflow-x-hidden'
                        : 'flex flex-col grow',
                )}
                data-cy="main-scroll-container"
                ref={scrollContainer}
            >
                {showHeader && !stickyTitle && (
                    <Header
                        title={view}
                        showBack={showBack}
                        showSettings={showSettings}
                        onBack={onBack}
                    />
                )}
                {children}
            </div>

            {showFooter && (
                <Footer activeItem={view} onBackToTopClicked={scrollToTop} />
            )}
        </div>
    );
}
