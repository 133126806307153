import { Calendar } from '@awesome-cordova-plugins/calendar';
import { ReactComponent as CalenderIcon } from '@public/icons/calender.svg';
import { Capacitor } from '@capacitor/core';
import { ReactComponent as ChevronLeft } from '@public/icons/chevron_left.svg';
import { FOOTER_HEIGHT_IN_PX } from '@utils/constants';
import { Livestream } from '@components/events/livestream';
import { LoadingSpinner } from '@components/loading-spinner';
import { NoData } from '@components/no-data-error';
import { Orientation, useOrientation } from '../hooks/useOrientation';
import { ParsedEvent } from '@api/validations';
import { ReactComponent as User } from '@public/icons/user.svg';
import { ViewsType } from '@typesSrc/views';
import { isEventLive } from '@components/events/event-overview';
import { shouldHidePriceTags } from '@utils/eventUtils';
import { useDirectus } from '../hooks/useDirectus';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseView from '@views/base-view';
import DefaultHeaderPicture from '@public/default_header_picture.jpg';
import FullscreenModalView from '@views/fullscreen-modal-view';
import Image from '../components/image';
import React, { useContext } from 'react';
import SafeSpaceContext from '../context/safe-space-context';
import Tag from '@components/card/tag';
import dayjs from '@utils/dayjs';

function EventView() {
    const { eventId } = useParams();
    const safeSpace = useContext(SafeSpaceContext);
    const { orientation } = useOrientation();
    const { data, loading } = useDirectus((directusClient) => {
        if (eventId) {
            return directusClient.getEvent(eventId);
        }
        return Promise.resolve(undefined);
    });

    const { t } = useTranslation('event');
    const navigate = useNavigate();
    if (loading) {
        return (
            <BaseView
                view={ViewsType.EVENTS}
                title={`Termin Detailansicht`}
                showHeader={false}
            >
                <div className="w-screen h-[55vh] mt-[72px] flex justify-center items-center">
                    <LoadingSpinner />
                </div>
            </BaseView>
        );
    }
    if (!data?.imported_event) {
        return (
            <FullscreenModalView>
                <BaseView
                    view={ViewsType.EVENTS}
                    title={`Termin Detailansicht`}
                    showHeader={true}
                >
                    <NoData />
                </BaseView>
            </FullscreenModalView>
        );
    }
    const { imported_event } = data;
    const {
        category,
        start_date,
        end_date,
        title,
        persons,
        description,
        shop_url,
    } = imported_event;
    const isThisYear = dayjs()
        .startOf('year')
        .isSame(start_date.startOf('year'), 'year');

    const renderImage = (imported_event: ParsedEvent['imported_event']) => {
        return imported_event.image ? (
            <Image
                src={
                    (
                        JSON.parse(imported_event.image) as {
                            url: string;
                            description: string;
                        }
                    ).url
                }
                alt={imported_event.title}
                className="h-auto w-full object-cover max-h-60 sm:max-h-[40vh] phoneLandscape:h-20"
                data-cy="eventThumbnail"
                aspectRatio={16 / 9}
            />
        ) : (
            <Image
                src={DefaultHeaderPicture}
                alt={imported_event.title}
                className="h-52 w-full object-cover sm:h-[35vh] phoneLandscape:h-20"
                data-cy="eventThumbnail"
            />
        );
    };

    const createPersonsEntry = (
        key: string,
        content: (string | undefined)[],
    ) => {
        return (
            <div key={key} className="mb-4">
                <div className="flex items-center">
                    <User />
                    <p className="ml-1 text-xs font-bold">
                        {t(`persons.${key}`)}
                    </p>
                </div>
                <div className="text-sm mt-1 font-medium">
                    {content.join(', ')}
                </div>
            </div>
        );
    };

    const createPersonsOverview = () => {
        if (!Object.values(persons).some((person) => !!person?.length)) {
            return null;
        }
        return (
            <div className="pt-5 pb-1 mt-5 border-y border-border">
                {Object.entries(persons).map(([key, value]) => {
                    if (!value?.length) {
                        return null;
                    }
                    return createPersonsEntry(key, value);
                })}
            </div>
        );
    };

    const saveToCalenderIfNotExistIOS = async (location: string) => {
        // check read/write permission
        if (!(await Calendar.hasReadWritePermission())) {
            await Calendar.requestReadWritePermission();
            return;
        }
        const event = (await Calendar.findEvent(
            title,
            location,
            undefined, // FIXME: Find a description format that does not crash the plugin on iOS. (BERDOM-145).
            start_date.toDate(),
            end_date.toDate(),
        )) as any[] | undefined;
        if (!event?.length) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const response = await Calendar.createEvent(
                    title,
                    location,
                    undefined, // FIXME: Find a description format that does not crash the plugin on iOS. (BERDOM-145).
                    start_date.toDate(),
                    end_date.toDate(),
                );
                if (typeof response !== 'string') {
                    throw new Error('saving not successful');
                }
            } catch (error) {
                console.error('Error Saving Event', title, error);
                window.alert(t('calender.error'));
            }
        }
    };

    const saveToCalenderIfNotExist = async () => {
        const LOCATION = 'Berliner Dom';
        if (Capacitor.getPlatform() === 'ios') {
            await saveToCalenderIfNotExistIOS(LOCATION);
        } else {
            try {
                await Calendar.createEventInteractivelyWithOptions(
                    title,
                    LOCATION,
                    description,
                    start_date.toDate(),
                    end_date.toDate(),
                );
            } catch (error) {
                // android throws success also as error
                if (error !== 'OK') {
                    console.error('Error Saving Event', title, error);
                    window.alert(t('calender.error'));
                }
            }
        }
    };

    const isLive = isEventLive(data);

    return (
        <FullscreenModalView>
            <BaseView
                view={ViewsType.EVENTS}
                title={`Termin Detailansicht: ${title}`}
                showHeader={false}
            >
                <div
                    className="relative"
                    style={{
                        paddingTop: `${safeSpace?.insets.top ?? 0}px`,
                    }}
                >
                    <button
                        onClick={() => navigate(-1)}
                        className="absolute bg-white left-4 p-4 rounded-full h-10 w-10 flex justify-center items-center"
                        data-cy="eventBackButton"
                        data-testid="eventBackButton"
                        style={{
                            top:
                                (isLive || imported_event.image) &&
                                orientation === Orientation.Portrait
                                    ? `${Math.max(
                                          16 + (safeSpace?.insets.top ?? 0),
                                          56,
                                      )}px`
                                    : `${16 + (safeSpace?.insets.top ?? 0)}px`,
                        }}
                    >
                        <ChevronLeft />
                    </button>
                    {isLive ? <Livestream /> : renderImage(imported_event)}
                </div>
                <div className="flex justify-center mx-4 pb-36">
                    <div className="grow max-w-2xl">
                        <div className="pt-4 ">
                            <div className="flex justify-between pb-3">
                                <p className="text-smaragd text-sm font-medium">
                                    {category}
                                </p>

                                {!shouldHidePriceTags(title) && (
                                    <Tag
                                        dataTestId="price-tag-event"
                                        text={
                                            !shop_url ? t('free') : t('ticket')
                                        }
                                        color={
                                            !shop_url
                                                ? 'bg-smaragd'
                                                : 'bg-sakrales-himbeer'
                                        }
                                    />
                                )}
                            </div>
                            <p
                                data-cy="eventTitle"
                                className="font-medium text-2xl pb-3 text-greyDark"
                            >
                                {title}
                            </p>
                            <div className="text-sm font-medium text-schiefer">
                                <p>
                                    {start_date.isSame(dayjs(), 'day')
                                        ? t('today')
                                        : ''}
                                    {start_date.format(
                                        isThisYear
                                            ? 'dddd DD.MM'
                                            : 'dddd DD.MM.YYYY',
                                    )}
                                </p>
                                <p>
                                    {`${start_date.format(
                                        'HH:mm',
                                    )} - ${end_date.format('HH:mm')}`}{' '}
                                    {t('time')}
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                                onClick={() => {
                                    void saveToCalenderIfNotExist();
                                }}
                                className="mt-5 py-2 px-16 rounded-lg flex justify-center border border-rosenholz text-rosenholz"
                                data-cy="addToCalendarButton"
                                data-testid="addToCalendarButton"
                            >
                                <CalenderIcon />
                                <p className="ml-2.5 font-bold text-sm">
                                    {t('calender.add')}
                                </p>
                            </button>
                        </div>

                        {createPersonsOverview()}

                        <div className="mt-5 whitespace-pre-line">
                            {description}
                        </div>
                    </div>
                </div>

                <div
                    data-cy="footerEvent"
                    className="fixed w-full pt-4 phoneLandscape:static"
                    style={{ bottom: `${FOOTER_HEIGHT_IN_PX}px` }}
                >
                    <div className="relative top-0.5 bg-gradient-to-t from-white w-full min-h-[90px] phoneLandscape:min-h-[0px]" />
                    {shop_url && (
                        <div className="relative top-px bg-white pb-9 flex justify-center pt-4">
                            <a
                                href={shop_url}
                                className="bg-rosenholz text-white font-bold text-sm px-28 py-2 rounded-lg"
                                data-cy="buyButtonEvent"
                            >
                                {t('buyTicket')}
                            </a>
                        </div>
                    )}
                </div>
            </BaseView>
        </FullscreenModalView>
    );
}

export default EventView;
