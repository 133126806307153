import { MutableRefObject, useEffect } from 'react';

export default function useFocusNextEmpyInput(
    refs: MutableRefObject<
        { value?: string; focus?: () => void } | null | undefined
    >[],
    enabled = true,
) {
    useEffect(() => {
        if (!enabled) {
            return;
        }
        for (const ref of refs) {
            if (ref.current && 'value' in ref.current && !ref.current.value) {
                ref.current.focus?.();
                return;
            }
        }
    }, [enabled, ...refs]);
}
