import { ReactComponent as Back } from '@public/icons/caret-left.svg';
import { HEADER_HEIGHT_IN_PX } from '@utils/constants';
import { Link } from 'react-router-dom';
import { ReactComponent as Settings } from '@public/icons/settings.svg';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import SafeSpaceContext from '../../context/safe-space-context';

function Header({
    title,
    showBack = false,
    showSettings = true,
    onBack,
}: {
    title: string;
    showBack?: boolean;
    showSettings?: boolean;
    onBack?: () => void;
}) {
    const insets = useContext(SafeSpaceContext);
    const { t } = useTranslation('header');

    const navigate = useNavigate();
    return (
        <header
            style={{
                height: `${HEADER_HEIGHT_IN_PX + (insets?.insets.top ?? 0)}px`,
            }}
        >
            <div
                className="top-0 w-full bg-background p-4 z-20"
                style={{ paddingTop: `${(insets?.insets.top ?? 0) + 16}px` }}
            >
                <div className="py-1 flex items-center relative">
                    {showBack ? (
                        <button
                            onClick={() => {
                                if (onBack) {
                                    onBack();
                                } else {
                                    navigate(-1);
                                }
                            }}
                            className="bg-white rounded-full h-10 w-10 p-[7px] border border-border absolute left-0"
                            data-testid="header-back-button"
                            data-cy="header-back-button"
                        >
                            <Back className="h-6 w-6 stroke-greyDark" />
                        </button>
                    ) : null}
                    <div
                        className="text-2xl text-greyDark leading-8 font-medium tracking-wide text-center grow"
                        data-testid="header-title"
                    >
                        {t(title)}
                    </div>
                    {showSettings && (
                        <Link
                            to="/einstellungen"
                            className="bg-white rounded-full h-10 w-10 p-[9px] border border-border absolute right-0"
                            data-testid="header-settings-button"
                        >
                            <Settings className="h-5 w-5 fill-greyDark" />
                        </Link>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
