import { classNames } from '@utils/class-names';
import React from 'react';

export default function Button(
    props: React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >,
) {
    return (
        <button
            {...props}
            className={classNames(
                'rounded-md text-white font-bold p-2 flex flex-row justify-center items-center',
                props.disabled ? 'bg-backgroundGray' : 'bg-rosenholz',
                props.className,
            )}
        />
    );
}
