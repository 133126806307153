import { classNames } from '@utils/class-names';
import React, { ReactElement } from 'react';

export default function Chooser<OPTION>({
    direction,
    options,
    onSelect,
    selected,
    className,
}: {
    direction: 'horizontal' | 'vertical';
    options: {
        image: ReactElement<any, any>;
        title: string;
        description?: string;
        value: OPTION;
    }[];
    onSelect: (value: OPTION) => void;
    selected: OPTION | null;
    className?: string;
}) {
    const isHorizontal = direction === 'horizontal';
    return (
        <div
            className={classNames(
                'flex gap-4',
                isHorizontal
                    ? 'flex-row w-full justify-items-stretch'
                    : 'flex-col',
                className,
            )}
        >
            {options.map(({ title, image, description, value }, index) => (
                <button
                    className={classNames(
                        'p-4 flex items-center rounded-md bg-white font-medium text-greyDark text-base',
                        selected === value
                            ? 'border-2 border-rosenholz -m-px'
                            : 'border border-lightGray',
                        isHorizontal ? 'flex-col flex-grow' : 'flex-row gap-6',
                    )}
                    key={index}
                    onClick={() => onSelect(value)}
                    type="button"
                    data-testid={`select-${title}`}
                >
                    <div
                        className={classNames(
                            isHorizontal && 'flex-grow flex items-center',
                        )}
                    >
                        {image}
                    </div>
                    <div
                        className={classNames(
                            'gap-1 flex flex-col',
                            !isHorizontal && 'items-start',
                        )}
                    >
                        {title}
                        {description && (
                            <p className="font-medium text-schiefer text-sm">
                                {description}
                            </p>
                        )}
                    </div>
                </button>
            ))}
        </div>
    );
}
