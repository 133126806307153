import { ReactComponent as Candle } from '@public/icons/candle.svg';
import { ReactComponent as Church } from '@public/icons/church.svg';
import { IntercessionOrderStep } from '@components/intercession/intercession-order';
import { Order, Timing } from '@typesSrc/intercession-order';
import { ValidationResult, validateEnum } from '@utils/validation';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';
import Chooser from '@components/forms/chooser';
import FormHeadline from '@components/forms/headline';
import React from 'react';

function RenderSelectTimingStep({
    order,
    onOrderChanged,
}: {
    order: Partial<Order>;
    onOrderChanged: (newOrder: Partial<Order>) => void;
}) {
    const { nextStep } = useWizard();
    const { t } = useTranslation('intercession', {
        keyPrefix: 'timing',
    });
    return (
        <>
            <FormHeadline>{t('headline')}</FormHeadline>
            <Chooser
                direction="vertical"
                options={[
                    {
                        title: t('noon.title'),
                        image: (
                            <Candle
                                className="text-rosenholz"
                                height={40}
                                width={40}
                            />
                        ),
                        description: t('noon.description'),
                        value: Timing.NOONTIME_PRAYER,
                    },
                    {
                        title: t('sunday.title'),
                        image: (
                            <Church
                                className="text-rosenholz"
                                height={40}
                                width={40}
                            />
                        ),
                        description: t('sunday.description'),
                        value: Timing.SUNDAY_SERVICE,
                    },
                ]}
                selected={order.timing ?? null}
                onSelect={(timing) => {
                    onOrderChanged({ timing });
                    setTimeout(() => void nextStep(), 100);
                }}
            />
        </>
    );
}

function isValid(order: Partial<Order>) {
    return validateEnum(order.timing, Timing) === ValidationResult.VALID;
}

const SelectTimingStep: IntercessionOrderStep = {
    ReactComponent: RenderSelectTimingStep,
    isValid,
};

export default SelectTimingStep;
