import { BackButtonListenerEvent } from '@capacitor/app/dist/esm/definitions';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';

export default class AppInitializer {
    public static init = async () => {
        await CapacitorApp.addListener(
            'backButton',
            ({ canGoBack }: BackButtonListenerEvent) => {
                if (document.fullscreenElement) {
                    void document.exitFullscreen();
                } else if (canGoBack) {
                    window.history.back();
                } else {
                    void CapacitorApp.exitApp();
                }
            },
        );

        if (Capacitor.isNativePlatform()) {
            await StatusBar.setStyle({ style: Style.Light });
            await StatusBar.setOverlaysWebView({ overlay: true });
        }
    };
}
