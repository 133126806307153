import { FOOTER_HEIGHT_IN_PX } from '@utils/constants';
import { ViewsType } from '@typesSrc/views';
import FooterItem from '@components/footer/footer-item';
import React from 'react';

function Footer({
    activeItem,
    onRedirect,
    onBackToTopClicked,
}: {
    activeItem: ViewsType;
    onRedirect?: () => void;
    onBackToTopClicked?: () => void;
}) {
    return (
        <footer
            data-cy="footer"
            style={{ height: `${FOOTER_HEIGHT_IN_PX}px` }}
            className="bg-white w-full pt-4 pb-8 border-t border-border"
        >
            <div className="px-4 max-w-2xl mx-auto grid grid-cols-4">
                <FooterItem
                    item={ViewsType.EVENTS}
                    isActive={activeItem === ViewsType.EVENTS}
                    onRedirect={onRedirect}
                    onBackToTopClicked={onBackToTopClicked}
                />
                <FooterItem
                    item={ViewsType.NEWS}
                    isActive={activeItem === ViewsType.NEWS}
                    onRedirect={onRedirect}
                    onBackToTopClicked={onBackToTopClicked}
                />
                <FooterItem
                    item={ViewsType.DONATION}
                    isActive={activeItem === ViewsType.DONATION}
                    onRedirect={onRedirect}
                    onBackToTopClicked={onBackToTopClicked}
                />
                <FooterItem
                    item={ViewsType.INTERCESSION}
                    isActive={activeItem === ViewsType.INTERCESSION}
                    onRedirect={onRedirect}
                    onBackToTopClicked={onBackToTopClicked}
                />
            </div>
        </footer>
    );
}

export default Footer;
