import { Link } from 'react-router-dom';
import { LoadingSpinner } from '@components/loading-spinner';
import { NEWS_BATCH_SIZE } from '@utils/constants';
import { NoData } from '@components/no-data-error';
import { ParsedNews } from '@api/validations';
import { directusClient } from '@api/directus-client';
import { useDirectus } from '../../hooks/useDirectus';
import Card from '@components/card/card';
import InfiniteScroll from 'react-infinite-scroller';
import React, { useContext, useMemo, useState } from 'react';
import SafeSpaceContext from '../../context/safe-space-context';
import groupBy from 'lodash.groupby';

function NewsOverview() {
    const insets = useContext(SafeSpaceContext);
    const { data, loading } = useDirectus((directusClient) =>
        directusClient.getAllNews(2 * NEWS_BATCH_SIZE, 0),
    );
    const [loadedData, setLoadedData] = useState<ParsedNews[]>(data ?? []);
    const [nextNewsList, setNextNewsList] = useState<JSX.Element[]>([]);
    const [numberOfNewsShown, setNumberOfNewsShown] = useState<number>(0);
    const [newsList, setNewsList] = useState<JSX.Element[]>([]);

    const generateNewsList = (newsData: ParsedNews[] | undefined) => {
        if (!newsData) {
            return [];
        }
        return Object.entries(
            groupBy<ParsedNews>(newsData, (news) => news.date?.format('YYYY')),
        )
            .reverse()
            .map(([key, value]) => {
                return (
                    <div key={key}>
                        <div
                            className="sticky z-10 bg-background text-center top-0 py-2 font-medium text-sm"
                            style={{
                                paddingTop: `${Math.max(
                                    insets?.insets.top ?? 0,
                                    8,
                                )}px`,
                            }}
                        >
                            {key}
                        </div>
                        <div className="flex justify-center w-100 mx-4">
                            <div className="grid sm:grid-cols-2 sm:gap-y-2.5 sm:gap-x-4 max-w-3xl">
                                {value.map((newsItem) => (
                                    <Link
                                        to={`${newsItem.id}`}
                                        key={newsItem.id}
                                    >
                                        <Card
                                            title={newsItem.title}
                                            teaserImageUrl={
                                                newsItem.teaser_image
                                            }
                                            description={newsItem.teaser_text}
                                            shortenDescription
                                        />
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            });
    };

    useMemo(() => {
        setNewsList(generateNewsList(data?.slice(0, numberOfNewsShown - 1)));
        setNextNewsList(generateNewsList(data));
        setNumberOfNewsShown(Math.min(NEWS_BATCH_SIZE, data?.length ?? 0));
        setLoadedData(data ?? []);
    }, [data]);

    /* istanbul ignore next */
    const loadMoreNews = () =>
        void (async () => {
            const offset = numberOfNewsShown + NEWS_BATCH_SIZE;
            setNumberOfNewsShown(loadedData.length);
            const data = await directusClient.getAllNews(
                NEWS_BATCH_SIZE,
                offset,
            );
            const newData = [...loadedData, ...(data ?? [])];
            if (data) {
                const newList = generateNewsList(newData);
                setNewsList(nextNewsList);
                setNextNewsList(newList);
                setLoadedData(newData);
            }
        })();

    const canLoadMore = numberOfNewsShown < loadedData.length;

    if (loading) {
        return (
            <div className="w-screen h-[55vh] flex justify-center items-center">
                <LoadingSpinner />
            </div>
        );
    }

    if (!data) {
        return <NoData />;
    }
    return (
        <InfiniteScroll
            loadMore={loadMoreNews}
            hasMore={canLoadMore}
            initialLoad={false}
            useWindow={false}
        >
            {newsList}
        </InfiniteScroll>
    );
}

export default NewsOverview;
