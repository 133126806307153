import { Order } from '@typesSrc/intercession-order';
import { Stripe } from 'stripe';
import { StripeData, directusClient } from '@api/directus-client';
import { openInAppBrowser } from '@utils/in-app-browser';
import { useCallback, useState } from 'react';

export enum CheckoutState {
    NOT_STARTED,
    PENDING,
    ERROR,
    SUCCESS,
}

export function useCheckout() {
    const [checkoutState, setCheckoutState] = useState<CheckoutState>(
        CheckoutState.NOT_STARTED,
    );

    const triggerCheckout = useCallback(
        (
            data: StripeData &
                (
                    | { type: 'donation' }
                    | ({ type: 'intercession' } & { order: Order })
                ),
        ) => {
            setCheckoutState(CheckoutState.PENDING);
            let sessionPromise: Promise<
                Stripe.Response<Stripe.Checkout.Session>
            >;
            if (data.type === 'donation') {
                sessionPromise = directusClient.checkoutDonation(data);
            } else {
                sessionPromise = directusClient.checkoutIntercession(data);
            }
            sessionPromise
                .then((session) => {
                    if (session.url) {
                        /* istanbul ignore next */
                        openInAppBrowser(session.url);
                        setCheckoutState(CheckoutState.SUCCESS);
                    } else {
                        setCheckoutState(CheckoutState.ERROR);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    setCheckoutState(CheckoutState.ERROR);
                });
        },
        [],
    );

    const reset = useCallback(() => {
        setCheckoutState(CheckoutState.NOT_STARTED);
    }, []);

    return {
        state: checkoutState,
        triggerCheckout,
        reset,
    };
}
