import { ReactComponent as Candle } from '@public/icons/candle.svg';
import { ReactComponent as Church } from '@public/icons/church.svg';
import { ReactComponent as Heart } from '@public/icons/heart.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as News } from '@public/icons/news.svg';
import { ViewsType } from '@typesSrc/views';
import { useTranslation } from 'react-i18next';
import React from 'react';

type FooterItemTypes = Extract<
    ViewsType,
    | ViewsType.EVENTS
    | ViewsType.NEWS
    | ViewsType.DONATION
    | ViewsType.INTERCESSION
>;

export const FooterItemTypeConfiguration: Record<
    FooterItemTypes,
    FooterItemConfiguration
> = {
    [ViewsType.EVENTS]: {
        icon: Church,
        path: '/termine',
    },
    [ViewsType.NEWS]: {
        icon: News,
        path: '/neuigkeiten',
    },
    [ViewsType.DONATION]: {
        icon: Heart,
        path: '/foerdern',
    },
    [ViewsType.INTERCESSION]: {
        icon: Candle,
        path: '/gebet',
    },
};

export type FooterItemConfiguration = {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    path: string;
};

function FooterItem({
    item,
    isActive,
    onRedirect,
    onBackToTopClicked,
}: {
    item: FooterItemTypes;
    isActive: boolean;
    onRedirect?: () => void;
    onBackToTopClicked?: () => void;
}) {
    const { t } = useTranslation('footer');
    const config = FooterItemTypeConfiguration[item];
    return (
        <Link
            className="flex flex-col items-center gap-0.5"
            to={config.path}
            onClick={
                /* istanbul ignore next: covered in cypress tests */
                () => {
                    if (isActive && onBackToTopClicked) {
                        onBackToTopClicked();
                    } else if (onRedirect) {
                        onRedirect();
                    }
                }
            }
        >
            <config.icon
                className={`h-6 w-auto ${
                    isActive ? 'fill-rosenholz' : 'fill-schiefer'
                }`}
            />
            <div
                className={`text-xs leading-6 ${
                    isActive ? 'text-rosenholz font-bold' : 'text-schiefer'
                }`}
            >
                {t(item)}
            </div>
        </Link>
    );
}

export default FooterItem;
