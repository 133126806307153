import { Dayjs } from '@utils/dayjs';
import { EURO_PRICE_FORMAT } from '@utils/constants';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import Image from '@components/image';
import React from 'react';
import Tag from '@components/card/tag';

type ImageHeight = 'fixed' | 'aspectRatio';

export type CardProps = {
    price?: number;
    teaserImageUrl: string | null;
    title: string;
    startDate?: Dayjs;
    endDate?: Dayjs;
    category?: string;
    live?: boolean;
    description?: string;
    selected?: boolean;
    loadingPrice?: boolean;
    shortenDescription?: boolean;
    free?: boolean | null;
    imageHeight?: ImageHeight;
    hidePriceTags?: boolean;
};

const PriceTag = ({
    price,
    loadingPrice,
    free,
}: Pick<CardProps, 'price' | 'loadingPrice' | 'free'>) => {
    const { t } = useTranslation('event');
    if (loadingPrice) {
        return (
            <Tag text={t('loadingPrice')} color="bg-backgroundGray" animate />
        );
    }
    return (
        <>
            {free !== null && (
                <Tag
                    dataTestId={'price-tag-card'}
                    text={free ? t('free') : t('ticket')}
                    color={free ? 'bg-smaragd' : 'bg-sakrales-himbeer'}
                />
            )}
            {price !== undefined && free === null && (
                <Tag
                    text={
                        price === 0
                            ? t('free')
                            : EURO_PRICE_FORMAT.format(price)
                    }
                    color={price === 0 ? 'bg-smaragd' : 'bg-sakrales-himbeer'}
                    dataTestId="price-tag-card"
                />
            )}
        </>
    );
};

export default function Card({
    teaserImageUrl,
    startDate,
    price,
    endDate,
    live,
    description,
    category,
    title,
    selected = false,
    loadingPrice = false,
    shortenDescription = false,
    free = null,
    imageHeight,
    hidePriceTags = false,
}: CardProps) {
    const { t } = useTranslation('event');
    const tags = (
        <div className="flex space-x-2 mb-2">
            {live && <Tag text={t('live')} color="bg-rosenholz" />}
            {!hidePriceTags && (
                <PriceTag
                    price={price}
                    loadingPrice={loadingPrice}
                    free={free}
                />
            )}
        </div>
    );

    return (
        <div
            className={classNames(
                'bg-white pb-4 mb-4 rounded-lg overflow-hidden shadow-card sm:h-[95%]',
                selected
                    ? 'border-2 border-rosenholz -m-px'
                    : 'border border-lightGray',
            )}
            data-cy="EventCard"
        >
            {teaserImageUrl &&
                getTeaserImage(teaserImageUrl, title, imageHeight)}

            <div className="mx-5 pt-4">
                {category ? (
                    <>
                        <div className="flex justify-between pb-1">
                            <p className="text-smaragd text-sm font-medium">
                                {category}
                            </p>
                            {tags}
                        </div>
                        <p className="font-bold">{title}</p>
                    </>
                ) : (
                    <div className="flex justify-between">
                        <p className="font-bold">{title}</p>
                        {tags}
                    </div>
                )}
                {startDate && (
                    <p className="text-sm font-medium">
                        {startDate.format('HH:mm')}
                        {endDate && `–${endDate.format('HH:mm')}`} {t('time')}
                    </p>
                )}
                {description && (
                    <p
                        className={classNames(
                            'whitespace-pre-line',
                            shortenDescription && 'line-clamp-2',
                        )}
                    >
                        {description}
                    </p>
                )}
            </div>
        </div>
    );
}

function getTeaserImage(
    teaserImageUrl: string,
    title: string,
    imageHeight?: ImageHeight,
) {
    return (
        <div className="flex justify-center">
            <Image
                src={teaserImageUrl}
                alt={title}
                className={classNames(
                    imageHeight === 'fixed' ? 'h-32' : 'aspect-[16/10]',
                    'w-full object-cover',
                )}
                sizes="(min-width: 640px) min(50vw, 350px), 100vw"
                aspectRatio={imageHeight === 'fixed' ? 300 / 128 : 16 / 10}
            />
        </div>
    );
}
