import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import React from 'react';

export const NoData = ({
    className,
}: {
    className?: string;
}): React.ReactElement => {
    const { t } = useTranslation('noData');
    const navigate = useNavigate();
    return (
        <div
            className={`w-screen h-[60vh] flex flex-col justify-center items-center ${
                className ?? ''
            }`}
        >
            <h1 className="font-bold text-2xl mb-5">{t('heading')}</h1>
            <p className="text-greyDark max-w-xs text-center">{t('details')}</p>
            <Button className="mt-10 w-44" onClick={() => navigate(-1)}>
                {t('button')}
            </Button>
        </div>
    );
};
