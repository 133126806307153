import { AppUrlOpenEvent } from '@typesSrc/events';
import { Environment } from '../environment.global';
import {
    InAppBrowser,
    InAppBrowserEvent,
    InAppBrowserObject,
} from '@awesome-cordova-plugins/in-app-browser';
import { Subscription } from 'rxjs';

let ref: InAppBrowserObject | null = null;
let eventSubscriptions: Subscription[] = [];

export function openInAppBrowser(url: string) {
    ref = InAppBrowser.create(url, '_blank', {
        usewkwebview: 'yes',
        location: 'no',
        toolbar: 'no',
        hidenavigationbuttons: 'yes',
        hardwareback: 'no',
        footer: 'no',
    });
    eventSubscriptions.push(
        ...[
            ref.on('loadstart').subscribe(checkDeepLink),
            ref.on('loadstop').subscribe(checkDeepLink),
            ref.on('loaderror').subscribe(checkDeepLink),
            ref.on('exit').subscribe(() => (ref = null)),
        ],
    );
}

export function closeInAppBrowser() {
    ref?.close();
    ref = null;
    eventSubscriptions.forEach((s) => s.unsubscribe());
    eventSubscriptions = [];
}

function checkDeepLink(browserEvent: InAppBrowserEvent) {
    const url = browserEvent.url;
    if (url.startsWith(Environment.PUBLIC_URL)) {
        const event = new Event('appUrlOpen');
        (event as AppUrlOpenEvent).url = url;
        window.dispatchEvent(event);
    }
}
