import { DirectusImage } from '@api/validations';
import { Environment } from '../environment.global';
import { generateSourceSet } from '@utils/loader';
import React, { ReactElement } from 'react';

type ImageProps = {
    sizes?: string;
    width?: number;
    height?: number;
    alt?: string;
    className?: string;
    aspectRatio?: number;
} & ({ src: string } | { asset: DirectusImage });

export default function Image(props: ImageProps): ReactElement {
    let src: string;
    let alt: string | undefined;
    if ('src' in props) {
        src = props.src;
        alt = props.alt;
    } else if ('asset' in props) {
        src = `${Environment.DIRECTUS_API_URL}/assets/${props.asset.id}`;
        alt = props.asset.title ?? undefined;
        /* istanbul ignore next */
    } else {
        console.error('Image component received invalid props', props);
        throw new Error('Invalid image props');
    }

    return (
        <img
            src={src}
            alt={alt}
            className={props.className}
            sizes={props.sizes ?? '100vw'}
            srcSet={generateSourceSet(src, undefined, props.aspectRatio)}
            width={props.width}
            height={props.height}
        />
    );
}
