export enum ValidationResult {
    TOO_LONG = 'TOO_LONG',
    WRONG_FORMAT = 'WRONG_FORMAT',
    EMPTY = 'EMPTY',
    VALID = 'VALID',
}

export type ValidatorFn = (input: string) => ValidationResult;

export const MAX_LENGTH_DEFAULT_STRING = 255;
export const MAX_LENGTH_MESSAGE = MAX_LENGTH_DEFAULT_STRING;

export const normalizeString = (string?: string | null) => string?.trim();

const emailRegex = /^.+@.+\..+$/;
export const validateEmail = (email?: string | null): ValidationResult => {
    email = normalizeString(email);
    if (!email || email.length === 0) {
        return ValidationResult.EMPTY;
    }
    if (email.length > MAX_LENGTH_DEFAULT_STRING) {
        return ValidationResult.TOO_LONG;
    }
    return email.match(emailRegex)
        ? ValidationResult.VALID
        : ValidationResult.WRONG_FORMAT;
};
export const isValidEmail = (email?: string | null): boolean =>
    validateEmail(email) === ValidationResult.VALID;

export const validateString = (string?: string | null): ValidationResult => {
    string = normalizeString(string);
    if (!string) {
        return ValidationResult.EMPTY;
    }
    if (string.length > MAX_LENGTH_DEFAULT_STRING) {
        return ValidationResult.TOO_LONG;
    }
    return ValidationResult.VALID;
};
export const isValidString = (string?: string | null): boolean =>
    validateString(string) === ValidationResult.VALID;

export const validateOptionalString = (
    string?: string | null,
): ValidationResult => {
    string = normalizeString(string);
    if (!string) {
        return ValidationResult.VALID;
    }
    return validateString(string);
};
export const isValidOptionalString = (string?: string | null): boolean =>
    validateOptionalString(string) === ValidationResult.VALID;

export const validateMessage = (message?: string | null): ValidationResult => {
    message = normalizeString(message);
    if (!message) {
        return ValidationResult.EMPTY;
    }
    if (message.length > MAX_LENGTH_MESSAGE) {
        return ValidationResult.TOO_LONG;
    }
    return ValidationResult.VALID;
};
export const isValidMessage = (message?: string | null): boolean =>
    validateMessage(message) === ValidationResult.VALID;

export const MAX_LENGTH_PHONE_NUMBER = 25;
const phoneRegex = /^(\+\d{1,24}|\d{1,25})$/;
export const validatePhoneNumber = (
    phone?: string | null,
): ValidationResult => {
    phone = normalizeString(phone);
    if (!phone) {
        return ValidationResult.EMPTY;
    }
    if (phone.length > MAX_LENGTH_PHONE_NUMBER) {
        return ValidationResult.TOO_LONG;
    }
    return phone.replaceAll(/[\s-]/g, '').match(phoneRegex)
        ? ValidationResult.VALID
        : ValidationResult.WRONG_FORMAT;
};
export const isValidPhoneNumber = (phone?: string | null): boolean =>
    validatePhoneNumber(phone) === ValidationResult.VALID;

export const validateEnum = (
    value: string | number | undefined | null,
    enumObject: Record<string | number, string | number>,
): ValidationResult => {
    if (value === undefined || value === null) {
        return ValidationResult.EMPTY;
    }
    return Object.values(enumObject).includes(value)
        ? ValidationResult.VALID
        : ValidationResult.WRONG_FORMAT;
};

export const MAX_LENGTH_ZIP = 5;
export const ZIP_REGEX_GERMANY = /^\d{5}$/;
export const validateGermanZipCode = (
    zip?: string | null,
): ValidationResult => {
    zip = normalizeString(zip);
    if (!zip) {
        return ValidationResult.EMPTY;
    }
    if (zip.length > MAX_LENGTH_ZIP) {
        return ValidationResult.TOO_LONG;
    }
    return zip.match(ZIP_REGEX_GERMANY)
        ? ValidationResult.VALID
        : ValidationResult.WRONG_FORMAT;
};
export const isValidGermanZipCode = (zip?: string | null) =>
    validateGermanZipCode(zip) === ValidationResult.VALID;
