import { CSSTransition } from 'react-transition-group';
import { classNames } from '@utils/class-names';
import { useWizard } from 'react-use-wizard';
import React, {
    MutableRefObject,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

/**
 * This component animates steps of a wizard with a left-right sliding animation.
 *
 * In order to achieve this effect, it uses position: absolute for the duration of the animation.
 * The steps' DOM and styles need to be compatible with that.
 *
 * Some steps use position: fixed. This breaks if it is used within a transformed DOM node. See https://stackoverflow.com/questions/2637058/positions-fixed-doesnt-work-when-using-webkit-transform
 * Instead, the AnimatedStep provides a ref to a fixedContainer that is both position: fixed and transformed _on the same element_.
 * Use it with `createPortal(<YourComponent/>, fixedContainer)`.
 *
 * Programmatically setting the focus needs to happen _after_ the transition animation.
 * Otherwise, the browser will move the focused element into view which overrides the animation.
 * Use the animating prop to set the focus after the animation.
 *
 * @param index
 * @param props
 * @constructor
 */
export default function AnimatedStep<T>({
    index,
    ...props
}: (
    | PropsWithChildren
    | {
          childProps: T;
          childFn: (
              props: T & {
                  fixedContainer: MutableRefObject<HTMLDivElement | null>;
                  animating: boolean;
              },
          ) => ReactElement;
      }
) & { index: number }) {
    const { activeStep } = useWizard();
    const previousStep = useRef(0);
    const reverseMotion =
        previousStep.current > activeStep || activeStep < index;

    useEffect(() => {
        previousStep.current = activeStep;
    }, [activeStep]);

    const ref = useRef(null);
    const fixedRef = useRef(null);
    const [animating, setAnimating] = useState(true);

    const animatingClasses =
        'transition-transform duration-200 w-full left-0 right-0';

    return (
        <div className="relative w-full">
            <CSSTransition
                in={index === activeStep}
                timeout={200}
                unmountOnExit
                nodeRef={ref}
                classNames={{
                    enter: classNames(
                        reverseMotion
                            ? '-translate-x-full'
                            : 'translate-x-full',
                    ),
                    enterActive: classNames(
                        animatingClasses,
                        'absolute',
                        '!translate-x-0',
                    ),
                    exit: 'translate-x-0',
                    exitActive: classNames(
                        animatingClasses,
                        'absolute',
                        reverseMotion
                            ? '!translate-x-full'
                            : '!-translate-x-full',
                    ),
                }}
                onEnter={() => setAnimating(true)}
                onEntered={() => setAnimating(false)}
                onExit={() => setAnimating(true)}
            >
                <div ref={ref}>
                    {'children' in props && props.children}
                    {'childProps' in props &&
                        props.childFn({
                            ...props.childProps,
                            fixedContainer: fixedRef,
                            animating,
                        })}
                </div>
            </CSSTransition>
            <CSSTransition
                in={index === activeStep}
                timeout={200}
                unmountOnExit
                nodeRef={fixedRef}
                classNames={{
                    enter: classNames(
                        reverseMotion
                            ? '-translate-x-full'
                            : 'translate-x-full',
                    ),
                    enterActive: classNames(animatingClasses, '!translate-x-0'),
                    exit: 'translate-x-0',
                    exitActive: classNames(
                        animatingClasses,
                        reverseMotion
                            ? '!translate-x-full'
                            : '!-translate-x-full',
                    ),
                }}
            >
                <div
                    ref={fixedRef}
                    className="fixed inset-0 pointer-events-none"
                />
            </CSSTransition>
        </div>
    );
}
