import { useCallback, useEffect, useRef, useState } from 'react';

const INITIAL_FOCUS = 0;

export default function useProgrammaticFocus<T extends HTMLElement>() {
    const ref = useRef<T>(null);
    const [_focus, setFocus] = useState(INITIAL_FOCUS);
    const focus = useCallback(() => {
        setFocus((oldFocus) => oldFocus + 1);
    }, []);

    useEffect(() => {
        if (_focus !== INITIAL_FOCUS && ref.current && 'focus' in ref.current) {
            ref.current.focus();
        }
    }, [_focus]);

    return [ref, focus] as const;
}
