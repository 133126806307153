import { LoadingSpinner } from '@components/loading-spinner';
import { NoData } from '@components/no-data-error';
import { classNames } from '@utils/class-names';
import { useDirectus } from '../../hooks/useDirectus';
import { useNavigate } from 'react-router';
import DonationAppealCard from '@components/donations/donation-appeal-card';
import React, { useRef } from 'react';

function DonationOverview() {
    const cardsRef = useRef<HTMLDivElement>(null);
    const { data, loading } = useDirectus((directusClient) =>
        directusClient.getAllDonationAppeals(),
    );

    const [activeCard, setActiveCard] = React.useState(0);
    const numCards = data?.length ?? 1;

    const navigate = useNavigate();

    /* istanbul ignore next */
    function scrollToCard(id: number) {
        cardsRef.current?.scrollTo({
            left:
                id * (cardsRef.current.offsetWidth - 48) +
                (activeCard === 0 ? 0 : -16),
        });
    }

    if (loading) {
        return (
            <div className="w-screen h-[55vh] flex justify-center items-center">
                <LoadingSpinner />
            </div>
        );
    }
    if (!data) {
        return <NoData />;
    }
    return (
        <div className="h-full pb-16 flex items-center">
            <div className="flex flex-col phoneLandscape:pb-0 h-full min-w-0 grow">
                <div
                    className="snap-mandatory snap-x pl-4 overflow-x-scroll h-full pb-2 scrollbar-hide scroll-smooth"
                    ref={cardsRef}
                    data-testid="DonationCards"
                    data-cy="DonationCards"
                    onScroll={
                        /* istanbul ignore next */ () => {
                            const scrollLeft =
                                cardsRef.current?.scrollLeft ?? 0;
                            const cardWidth =
                                cardsRef.current?.offsetWidth ?? 0;
                            const newActiveCard = Math.round(
                                scrollLeft / (cardWidth - (numCards - 1) * 16),
                            );
                            setActiveCard(newActiveCard);
                        }
                    }
                >
                    <div className="flex flex-row space-x-4 w-max h-full">
                        {data.map(
                            ({ id, title, description, image }, index) => (
                                <DonationAppealCard
                                    key={title}
                                    title={title}
                                    description={description}
                                    image={image ?? undefined}
                                    scrollToCard={scrollToCard}
                                    activeCard={activeCard}
                                    index={index}
                                    directusID={id}
                                />
                            ),
                        )}
                    </div>
                </div>

                <div className="my-4 w-full flex flex-row justify-center space-x-3 sm:mt-12">
                    {data.map((_, id) => (
                        <button
                            key={id}
                            className={classNames(
                                'w-2 h-2 rounded',
                                id === activeCard
                                    ? 'bg-greyDark'
                                    : 'bg-greyDark/30',
                            )}
                            onClick={() => {
                                scrollToCard(id);
                            }}
                            data-testid={`DonationCardIndicator${id}`}
                            disabled={id === activeCard}
                        />
                    ))}
                </div>

                <div className="phoneLandscape:relative phoneLandscape:bottom-0 grow flex justify-center w-screen py-4 px-4 border-b-lightGray border-b absolute bottom-24 left-0 bg-white flex justify-center">
                    <button
                        className="w-full max-w-2xl p-2 bg-rosenholz rounded-lg text-center text-white"
                        onClick={() => {
                            const id = data[activeCard].id;
                            if (id) {
                                navigate(`/foerdern/${id}`);
                            }
                        }}
                        data-testid="donateButton"
                        data-cy="donateButton"
                    >
                        Jetzt spenden
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DonationOverview;
